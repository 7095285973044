import React, { useContext, useState } from 'react'

import { addDoc } from '@firebase/firestore';
import { Form, FormItemProps, Modal, Radio, Select } from 'antd';
import { RcFile } from 'antd/es/upload/interface';
import { message } from 'antd/lib';
import { query, where } from 'firebase/firestore';
import { ref as storageRef } from 'firebase/storage';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { useCollection } from 'react-firebase-hooks/firestore';
import { useUploadFile } from 'react-firebase-hooks/storage';
import { useNavigate } from 'react-router-dom';

import { FileDragger } from '@/components/base/FileDragger/FileDragger';
import { AuthData, AuthDataContext } from '@/components/containers/AuthContext';
import { clientCompanyRef } from '@/firestore/api/clientCompany.ts';
import { companyEntityRef } from '@/firestore/api/companyEntity.ts';
import { storage } from '@/firestore/config.ts';
import { FORM_ITEMS, Period } from '@/pages/FilesPage/AddNewReportModal';
import { firstLetterToUpperCase } from '@/widgets/NotesLibrary';

import { RegRepNewModalProps } from './RegRepNewModal.types'
import { CUSTOM_MODAL_WIDTH } from '../../../constants/modalWidth.ts';
import { ROUTES_CONFIG_REG_REP } from '../../../constants/routes.ts';
import { REG_REP_FORM_ITEMS, RegRepProcess, regRepProcessRef, UploadType } from '../../../firestore/api/regRepProcess.ts';

export enum S213Format {
    generic = 'generic',
    bob50 ='bob50',
}

const PERIOD_SELECT_OPTIONS: Array<{value: Period, label: string}> = [
    { value: 'quarterly',
        label: 'Quarterly' },
    { value: 'monthly',
        label: 'Monthly' },
]

export const RegRepNewModal = (props: RegRepNewModalProps) => {
    const [form] = Form.useForm();
    const authData = useContext<AuthData>(AuthDataContext)
    const [uploadFile, uploading] = useUploadFile();
    const navigate = useNavigate()
    
    const [s213Type, setS213Type] = useState<S213Format>(S213Format.generic)
    
    const [companyEntityItems, companyEntityLoading] = useCollection(query(companyEntityRef, where('companyId', '==' , authData.company.id)));
    const [clientCompanyItems, clientCompanyLoading] = useCollection(query(clientCompanyRef, where('companyId', '==' , authData.company.id)));

    const [acitveCompanyId, setActiveCompanyId] = useState<string>()

    const handleFormSubmit = async (data: Partial<RegRepProcess>) => {
        try {
            const newDocPayload: RegRepProcess = {
                clientCompanyId: data.clientCompanyId,
                entityId: data.entityId,

                // period: data.period,
                s213format: s213Type,

                status: 
                    s213Type === 'bob50' 
                        ? 'preparingS213' :
                        // Initial of the new pipeline
                        data.fileS213?.file?.response?.metadata?.fullPath?.length
                            ? 'initial'
                            // Processing is initial for the old pipeline
                            : 'processing',
                validationStatus: 'processing',
                userId: authData.user.uid,
                companyId: authData.company.id,

                uploadedAt: new Date(),
            }

            if(data.uploadType) {
                newDocPayload.uploadType = data.uploadType as UploadType
            }
            if(data.fileTPTOBS?.file?.response?.metadata?.fullPath) {
                newDocPayload.fileTPTOBS = data.fileTPTOBS.file.response.metadata.fullPath
            }

            if(data.fileS213bob50?.file?.response?.metadata?.fullPath) {
                newDocPayload.fileS213bob50 = data.fileS213bob50.file.response.metadata.fullPath
            }
            if(data.fileS213Bob50Mapping?.file?.response?.metadata?.fullPath) {
                newDocPayload.fileS213Bob50Mapping = data.fileS213Bob50Mapping.file.response.metadata.fullPath
            }
            if(data.fileU11?.file?.response?.metadata?.fullPath) {
                newDocPayload.fileU11 = data.fileU11.file.response.metadata.fullPath
            }
            if(data.fileS213?.file?.response?.metadata?.fullPath) {
                newDocPayload.fileS213 = data.fileS213.file.response.metadata.fullPath
            }
            if(data.fileS214?.file?.response?.metadata?.fullPath) {
                newDocPayload.fileS214 = data.fileS214.file.response.metadata.fullPath
            }

            await addDoc(regRepProcessRef, newDocPayload)
        } catch (error) {
            console.error(error)
            message.error('Failed to create new report')
        }
    }

    const handleOk = () => {
        form
            .validateFields()
            .then(async (values) => {
                await handleFormSubmit(values)
                form.resetFields();
                navigate(ROUTES_CONFIG_REG_REP.REG_REP.path)
            })
            .catch((info) => {
                console.error(info)
                message.error('Please fill in all required fields before submitting')
            })
    }
    
    const draggerCustomRequest = async (formItemProps: FormItemProps, data: UploadRequestOption) => {
        data.onProgress({ percent: 0 })

        const file = data.file as RcFile

        const filePath = `reg-rep/companies/${authData.company.id}/${formItemProps.name}/${file.uid}`

        const res = await uploadFile(storageRef(storage, filePath), data.file)

        if(!res) {
            throw new Error('File upload failed')
        }

        // // Save filename in form if main report file
        // if (!form.getFieldValue(FORM_ITEMS.name.name) && formItemProps.name === ReportType.reportFile) {
        //     form.setFieldsValue({ [FORM_ITEMS.name.name]: file.name })
        // }

        data.onProgress({ percent: 100 })
        data.onSuccess(res);
    }
    
    const handleCancel = () => {
        form.resetFields();
        navigate(ROUTES_CONFIG_REG_REP.REG_REP.path)
    }
    
    return (
        <Modal
            open
            confirmLoading={uploading}
            title={ROUTES_CONFIG_REG_REP.REG_REP_NEW.title}
            centered
            onOk={handleOk}
            onCancel={handleCancel}
            width={CUSTOM_MODAL_WIDTH.medium}
            okText='Upload'
            styles={{
                body: {
                    overflowY: 'auto',
                },
                content: {
                    overflowY: 'hidden',
                    maxHeight: '90vh',
                    display: 'flex',
                    flexDirection: 'column',
                },
            }}
        >
            <Form
                form={form}
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                onFinish={handleFormSubmit}
                autoComplete='off'
                scrollToFirstError={true}
                layout='vertical'
            >
                {/*<Form.Item*/}
                {/*    {...FORM_ITEMS.period}*/}
                {/*>*/}
                {/*    <Select*/}
                {/*        placeholder={FORM_ITEMS.period.label}*/}
                {/*    >*/}
                {/*        {*/}
                {/*            PERIOD_SELECT_OPTIONS.map((item) => (*/}
                {/*                <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>*/}
                {/*            ))*/}
                {/*        }*/}
                {/*    </Select>*/}
                {/*</Form.Item>*/}

                <Form.Item
                    {...FORM_ITEMS.clientCompanyId}
                >
                    <Select
                        defaultActiveFirstOption
                        placeholder={FORM_ITEMS.clientCompanyId.label}
                        loading={clientCompanyLoading}
                        onSelect={(id) => {
                            setActiveCompanyId(id)
                        }}
                    >
                        {clientCompanyItems?.docs.map((item) => (
                            <Select.Option key={item.id} value={item.id}>{item.data().name}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    {...FORM_ITEMS.entityId}
                >
                    <Select
                        defaultActiveFirstOption
                        placeholder={FORM_ITEMS.entityId.label}
                        loading={companyEntityLoading}
                    >
                        {companyEntityItems?.docs
                            .filter((item) => item.data().clientCompanyId === acitveCompanyId)
                            .map((item) => (
                            // FEXME: Can be used .ref.path instead of id
                                <Select.Option key={item.id} value={item.id}>{item.data().name}</Select.Option>
                            ))}
                    </Select>
                </Form.Item>   
                <Form.Item
                    {...FORM_ITEMS.uploadType}
                >
                    <Select
                        defaultActiveFirstOption
                        placeholder={FORM_ITEMS.uploadType.label}
                    >
                        {Object.keys(UploadType)?.map((item) => (
                            <Select.Option key={item} value={item}>{firstLetterToUpperCase(item)}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                
                {/*<Form.Item*/}
                {/*    {...REG_REP_FORM_ITEMS.fileU11}*/}
                {/*>*/}
                {/*    <FileDragger*/}
                {/*        customRequest={(options: UploadRequestOption) => draggerCustomRequest(REG_REP_FORM_ITEMS.fileU11, options)}*/}
                {/*        formats={['xlsx']}*/}
                {/*    />*/}
                {/*</Form.Item>*/}
                <Form.Item
                    {...REG_REP_FORM_ITEMS.fileTPTOBS}
                >
                    <FileDragger
                        customRequest={(options: UploadRequestOption) => draggerCustomRequest(REG_REP_FORM_ITEMS.fileTPTOBS, options)}
                        formats={['xlsx']}
                    />
                </Form.Item>
                <Form.Item
                    {...REG_REP_FORM_ITEMS.s213format}
                >
                    <Radio.Group value={s213Type} onChange={(e) => setS213Type(e.target.value)}>
                        <Radio.Button value={S213Format.generic}>Generic</Radio.Button>
                        <Radio.Button value={S213Format.bob50}>BOB50</Radio.Button>
                    </Radio.Group>
                </Form.Item>
                {s213Type === S213Format.generic ? (
                    <Form.Item
                        {...REG_REP_FORM_ITEMS.fileS213}
                        // required={s213Type === S213Format.generic}
                    >
                        <FileDragger
                            customRequest={(options: UploadRequestOption) => draggerCustomRequest(REG_REP_FORM_ITEMS.fileS213, options)}
                            formats={['xlsx']}
                        />
                    </Form.Item>
                ) : (
                    <>
                        <Form.Item
                            {...REG_REP_FORM_ITEMS.fileS213bob50}
                            required={s213Type === S213Format.bob50}
                        >
                            <FileDragger
                                customRequest={(options: UploadRequestOption) => draggerCustomRequest(REG_REP_FORM_ITEMS.fileS213bob50, options)}
                                formats={['xlsx']}
                            />
                        </Form.Item>    
                        <Form.Item
                            {...REG_REP_FORM_ITEMS.fileS213Bob50Mapping}
                            required={s213Type === S213Format.bob50}
                        >
                            <FileDragger
                                customRequest={(options: UploadRequestOption) => draggerCustomRequest(REG_REP_FORM_ITEMS.fileS213Bob50Mapping, options)}
                                formats={['xlsx']}
                            />
                        </Form.Item>
                    </>
                )}
                <Form.Item
                    {...REG_REP_FORM_ITEMS.fileS214}
                >
                    <FileDragger
                        customRequest={(options: UploadRequestOption) => draggerCustomRequest(REG_REP_FORM_ITEMS.fileS214, options)}
                        formats={['xlsx']}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
}
