import { useCallback, useContext, useEffect, useRef } from 'react';

import { DocumentSnapshot, deleteDoc, setDoc } from '@firebase/firestore';
import { Core } from '@pdftron/webviewer';
import { doc, updateDoc } from 'firebase/firestore';
import { useDocument } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';

import { PdfViewerContext2 } from '@/App.tsx';
import { AuthData, AuthDataContext } from '@/components/containers/AuthContext';
import { QUERY_PARAMS_CONFIG } from '@/config/queryParams.ts';
import { CustomDataKey } from '@/constants/pdfViewer/customDataKey.ts';
import {
    fetchReportAnnotationOnce,
    ReportAnnotation,
    useReportAnnotationCreateMutation, useReportAnnotationUpdateMutation,
} from '@/firestore/api/reportAnnotation.ts';
import {
    ReportExtractedValues,
    reportExtractedValuesRef,
    useReportExtractedValuesCreateMutation,
} from '@/firestore/api/reportExtractedValues.ts';
import { ReportReview, reportReviewRef } from '@/firestore/api/reportReview.ts';
import { ReviewIdentifiedBlock, reviewIdentifiedBlockRef } from '@/firestore/api/reviewIdentifiedBlock.ts';
import { useFocusedValueId } from '@/hooks/useFocusedValueId.ts';
import { useViewerDocument } from '@/hooks/useViewerDocument.ts';
import { AnnotationChangeSource } from '@/types/pdfLib/annotationChangeSource.ts';
import { focusBySnapId } from '@/utils/pdfViewer/focusBySnapId.ts';
import { SUM_SELECT_QUERY_PARAM, TICK_MARK_QUERY_CONFIG } from '@/widgets/MagicButtons/MagicButtons.constants.ts';
import { ACTIVE_IC_VALUE_QUERY_PARAM } from '@/widgets/MoneyValuesNavigator/MoneyValuesNavigator.constants.ts';
import { AnnotationVariant, CustomToolNames } from '@/widgets/PdfViewer2/index.ts';
import { getReviewedValueTitle } from '@/widgets/PdfViewer2/PdfViewer2.utils.ts';

export type AnnotationUpdateType = 'modify' | 'add' | 'delete'

interface HandleTickMarkAnnotationParams {
    text: string
    coords: number[]
    page: number
    annotation: Core.Annotations.Annotation
}

interface HandleCommonAnnotatinonParams {
    annotations: Core.Annotations.Annotation[]
    updateType: AnnotationUpdateType
    currentStep: string
}

interface UseAnnotationsListenerParams {
    reportSnapshot: DocumentSnapshot<ReportReview>
}

export function getQueryParam(name: string): string | null {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
}

/**
 * FIXME: Show alert if firebase sync failed
 */
export const useAnnotationsListener = ({}: UseAnnotationsListenerParams) => {
    const { pdfInstance } = useContext(PdfViewerContext2)
    const { id: docId } = useParams()
    const authData = useContext<AuthData>(AuthDataContext)

    const [, setActiveMoneyValue] = useQueryParam(ACTIVE_IC_VALUE_QUERY_PARAM.name, ACTIVE_IC_VALUE_QUERY_PARAM.type)
    const { setSelectedExtractedValSnapId } = useFocusedValueId()
    
    const { annotationManager } = useViewerDocument()
    
    const [reportSnapshot, isReportSnapshotLoading] = useDocument<ReportReview>(doc(reportReviewRef, docId))
    const reportData = reportSnapshot?.data();
    const currentStep = reportData?.currentStep

    const handledCreateEventForIds = useRef<string[]>([])
    const handledCreateEventForSnapshotIds = useRef<string[]>([])

    // IS that a link with notes-ecdf ?
    const handleLinkAnnotationSelect = (annotation: Core.Annotations.Annotation) => {
        const linkType = annotation.getCustomData(CustomDataKey.crossLInkType)
        const moneyValue = annotation.getCustomData(CustomDataKey.normalizedValue)

        if(linkType === 'money') {
            if(moneyValue) {
                setActiveMoneyValue([moneyValue])
            } else {
                console.error('Money value is not defined')
            }

            return;
        }

        const docViewer = pdfInstance.Core.documentViewer as Core.DocumentViewer;
        const annotManager = docViewer.getAnnotationManager();

        if (!annotManager || !annotation) return

        const annotations = annotManager.getAnnotationsList()
        const linkGroup = annotation.getCustomData('linkGroup')
        const sameLinkGroup = annotations.filter(el => Boolean(linkGroup?.length) && (el.getCustomData('linkGroup') === linkGroup))

        const currentInd = sameLinkGroup.findIndex(el => el.Id === annotation.Id)
        const nextInd = currentInd + 1 >= sameLinkGroup.length ? 0 : currentInd + 1
        const nextAnnotation = sameLinkGroup[nextInd]

        annotManager.deselectAllAnnotations()
        annotManager.jumpToAnnotation(nextAnnotation);
        annotManager.selectAnnotation(nextAnnotation);
    }

    // TODO: Remane/Move
    // Common annotations
    const annotationToDoc = (annotation: Core.Annotations.Annotation, xfdfString: string, currentStep: string): ReportAnnotation => {
        if (!currentStep && reportData?.reviewStatus === 'inProgress') {
            console.error('Current step is not defined')
        }

        const ignoreStepKey = annotation.getCustomData('ignoreStepKey') === 'true'

        const res = {
            page: annotation.getPageNumber() - 1,
            stepKey: (ignoreStepKey || !currentStep) ? null : currentStep,
            anotation: xfdfString,
            companyId: authData.company.id,
            reportId: docId,
            annotationId: annotation.Id,
        }

        return res
    }
    
    const reportExtractedValuesCreateMutation = useReportExtractedValuesCreateMutation()

    const reportAnnotationCreateMutation = useReportAnnotationCreateMutation()
    const reportAnnotationUpdateMutation = useReportAnnotationUpdateMutation()
    
    /**
     * Annotation triggered manually by user without active tool
     */
    const handleCommonAnnotation = useCallback(async ({
        annotations,
        updateType,
        currentStep,
    }: HandleCommonAnnotatinonParams) => {
        const docViewer = pdfInstance.Core.documentViewer as Core.DocumentViewer;
       
        const annotManager = docViewer.getAnnotationManager();
        
        const normalizedAnnotationsPromises: Promise<ReportAnnotation>[] = annotations?.map(async (annotation) => {
            annotation.setCustomData(CustomDataKey.toolName, annotation.ToolName);

            const xfdfString = await annotManager.exportAnnotations({
                annotList: [annotation],
                widgets: true,
                links: true,
                fields: true,
                // useDisplayAuthor: true,
                // generateInlineAppearances: true,
            });

            return annotationToDoc(annotation, xfdfString, currentStep)
        }) ?? []

        const normalizedAnnotations = await Promise.all(normalizedAnnotationsPromises)

        const idsToSearch = annotations.map(el => el.Id.toString())
        
        const reportAnnotationData = await fetchReportAnnotationOnce({
            filters: [
                'and',
                ['annotationId', 'in', [...idsToSearch]],
            ],
        })

        try {
            if (updateType === 'delete') {
                const promisesDelete = normalizedAnnotations.map(async (annotation) => {
                    const item = reportAnnotationData?.find(el => el.annotationId === annotation.annotationId)
                    if (!item) return Promise.resolve()

                    return await reportAnnotationUpdateMutation.mutateAsync({
                        id: item.id,
                        data: {
                            deletedAt: new Date(),
                        },
                    })
                })

                await Promise.all(promisesDelete ?? [])
            } else if (updateType === 'add') {
                if(normalizedAnnotations[0].annotationId) {
                    setFocusedId(normalizedAnnotations[0].annotationId)
                }
                
                const promisesCreate = normalizedAnnotations.map((annotation) => {
                    return reportAnnotationCreateMutation.mutateAsync({
                        data: annotation,
                    })
                })
                await Promise.all(promisesCreate)

                const relatedSnapshotId = annotations[0]?.getCustomData('relatedSnapshotId')

                // TODO: Move it out
                const annotationOnlyWhenCreate = annotations[0]?.getCustomData('annotationOnlyWhenCreate') === 'true'
                const isTickMark = annotations[0]?.getCustomData('toolName') === CustomToolNames.TickMark
                // Kind of local hack for tick mark. Since event doesn't trigger for first render sometimes, I need to do update only after event was triggered
                if (annotationOnlyWhenCreate && relatedSnapshotId && isTickMark) {
                    const docRef = doc(reviewIdentifiedBlockRef, relatedSnapshotId)
                    await updateDoc(docRef, { annotaionId: annotations[0].Id })
                }

                // Temporary disabled. FIXME
                // const linkSnapshotId = annotations[0]?.getCustomData('relatedLinkSnapshotId')
                // const isLink = annotations[0]?.getCustomData('toolName') === CustomToolNames.CrossLink
                // if(linkSnapshotId?.length && isLink) {
                //     const docRef = doc(reviewLinkedObjectsRef, linkSnapshotId)
                //     await updateDoc(docRef, { linksCreated: true })
                //
                // }
            } else if (updateType === 'modify') {
                const promisesUpdate = normalizedAnnotations.map((annotation) => {
                    const item = reportAnnotationData?.find(el => el.annotationId === annotation.annotationId)
                    if (!item) return Promise.resolve()

                    return reportAnnotationUpdateMutation.mutateAsync({
                        id: item.id,
                        data: annotation,
                    })
                })
                await Promise.all(promisesUpdate)
            }

        } catch (error) {
            console.error('Failed to sync with firebase:', error)

        }
    }, [pdfInstance])

    const handleValueIdentifierAnnotation = async ({
        annotations,
    } : {
        annotations: Core.Annotations.Annotation[]
    }) => {
        const docViewer = pdfInstance.Core.documentViewer as Core.DocumentViewer
        const annotManager = docViewer.getAnnotationManager();
        
        const annotation = annotations[0]

        const pageNumber = annotation.getPageNumber()
        const rect = annotation.getRect()

        const text = await docViewer.getDocument().getTextByPageAndRect(pageNumber, rect)

        const newDoc = doc(reportExtractedValuesRef)
        
        annotation.setCustomData(CustomDataKey.relatedSnapshotId, newDoc.id)
        annotation.setCustomData(CustomDataKey.annotationVariant, AnnotationVariant.moneyValue)

        annotation.setContents(getReviewedValueTitle(text))

        annotManager.redrawAnnotation(annotation)
        
        const newItem: ReportExtractedValues = {
            companyId: authData.company.id,
            coords: [rect.x1, rect.y1, rect.getWidth(), rect.getHeight()],
            coordinates: {
                x0: rect.x1,
                y0: rect.y1,
                width: rect.getWidth(),
                height: rect.getHeight(),
            },
            originalValue: text,
            page: pageNumber - 1 ,
            reportId: docId as string,
            type: 'manual',
            createdBy: authData.user.uid,
            createdAt: new Date(),
        }

        await reportExtractedValuesCreateMutation.mutateAsync({
            data: newItem,
            customId: newDoc.id,
        })
        
        setSelectedExtractedValSnapId(newDoc.id)
    }
    
    const handleTickMarkAnnotation = useCallback(async ({
        text, coords, page, annotation,
    }: HandleTickMarkAnnotationParams) => {
        const newItem: ReviewIdentifiedBlock = {
            content: text,
            pageIndex: page,
            companyId: authData.company.id,
            entityId: reportData.entityId,
            reportId: reportSnapshot.id,
            manualConfidence: 'neutral',
            stepKey: currentStep || null,
            coordinates: [coords[0], coords[1], coords[2], coords[3]],
            blockType: 'unknown',
            rejected: false,
            createdAt: new Date(),
            annotaionId: annotation.Id,
        }

        const newDoc = doc(reviewIdentifiedBlockRef)

        annotation.setCustomData(CustomDataKey.relatedSnapshotId, newDoc.id)
        annotation.setCustomData(CustomDataKey.relatedStep, reportData?.currentStep || '')
        annotation.setCustomData(CustomDataKey.tickConfidence, 'neutral')
        
        annotation.setBorderStyle('dash')
        
        annotationManager?.redrawAnnotation(annotation)

        await setDoc(newDoc, newItem)
    }, [pdfInstance, reportSnapshot, authData, reportData?.currentStep])

    const [, setFocusedId] = useQueryParam(QUERY_PARAMS_CONFIG.FOCUSED_ANNOTATION_QUERY_PARAM.key, QUERY_PARAMS_CONFIG.FOCUSED_ANNOTATION_QUERY_PARAM.type)
    const [, setFocusedExtractedValueSnapId] = useQueryParam(QUERY_PARAMS_CONFIG.FOCUSED_EXTRACTED_VALUE_SNAP_ID_QUERY_PARAM.key, QUERY_PARAMS_CONFIG.FOCUSED_EXTRACTED_VALUE_SNAP_ID_QUERY_PARAM.type)

    /**
     * NOTE
     * - Update action triggeres change only after next
     */
    const listenerCallback = useCallback((annotations: Core.Annotations.Annotation[], action: AnnotationUpdateType, {
        imported,
        source,
        isUndoRedo,
    }) => {
        if (!pdfInstance) return

        if (imported) {
            return;
        }

        if (!annotations.length) return

        const annotation = annotations[0]

        // Custom handler for messages
        if(annotation?.isReply()) {
            // Handled in 'useReplyAnnotationHandler'
            return;
        }

        // For the cause of manual event trigger
        if (handledCreateEventForIds.current.includes(annotations[0].Id) && action === 'add') {
            // console.info('Skipped adding annotation', annotations[0].Id)
            return;
        } else {
            handledCreateEventForIds.current.push(annotations[0].Id)
        }
        
        const skipEventHandlers = annotations[0]?.getCustomData(CustomDataKey.skipEventHandlers) === 'true'
        if (skipEventHandlers) {
            return
        }

        const isLink = annotations[0]?.getCustomData('toolName') === CustomToolNames.CrossLink
        // FIXME: Temporary ignore links
        if (isLink && (action === 'add' || action === 'delete' || action === 'modify')) {
            return
        }

        const linkIndex = annotations[0]?.getCustomData('linkIndex')
        // Need separate logic for link annotations becuase many of them related to one index
        // FIXME: Can be done with universal logic and using annotationIndex for expample
        const relatedLinkSnapshotId = linkIndex?.length ? annotations[0]?.getCustomData('relatedLinkSnapshotId') + '_' + linkIndex : null
        // Just in case the same identified block triggered two events
        const relatedSnapshotId = annotations[0]?.getCustomData('relatedSnapshotId')
        const snapshotId = relatedSnapshotId || relatedLinkSnapshotId
        if (snapshotId?.length && handledCreateEventForSnapshotIds.current.includes(relatedSnapshotId) && action === 'add') {
            // console.info('Skipped adding annotation', relatedSnapshotId)
            return;
        } else {
            handledCreateEventForSnapshotIds.current.push(relatedSnapshotId)
        }

        (async () => {
            // FIXME: Shold work now. Before params extraction was broken because of listener scope
            const isTickMarkOpen = getQueryParam(TICK_MARK_QUERY_CONFIG.name) === '1'
            const isSumSelectOpen = getQueryParam(SUM_SELECT_QUERY_PARAM.name) === '1'

            // Delete without saving to firebase
            if (action === 'delete' && source === AnnotationChangeSource.temporaryDelete) {
                return
            }

            // Ignore this type of source
            if (action === 'add' && source === AnnotationChangeSource.temporaryCreate) {

                return
            }

            if (action === 'delete') {

            }

            const isTickMark = annotations[0]?.ToolName === CustomToolNames.TickMark || annotations[0]?.getCustomData('toolName') === CustomToolNames.TickMark
            const isSumSelect = annotations[0]?.ToolName === CustomToolNames.SumSelect || annotations[0]?.getCustomData('toolName') === CustomToolNames.SumSelect
            const isValueIdentifier = annotations[0]?.ToolName === CustomToolNames.ValueIdentify || annotations[0]?.getCustomData('toolName') === CustomToolNames.ValueIdentify

            if(isSumSelect) {
                return
            }

            // Ignore custom blocks scenario and create common annotations
            const annotationOnlyWhenCreate = annotations[0]?.getCustomData('annotationOnlyWhenCreate') === 'true'
            const skipCustomHandler = annotationOnlyWhenCreate && action === 'add' && (isTickMark || isSumSelect)

            if (!skipCustomHandler) {
                // Want to catch any TickMark annotation event (not only from active tool)
                if (isTickMark) {

                    const annotation: Core.Annotations.Annotation = annotations[0]

                    // Case of creating annotations for Identified blocks
                    if (action === 'add') {
                        // await updateDoc(block.ref, { annotaionId: annotationObj.Id })
                    }

                    if (action === 'delete') {

                        // Delete if there is a related block
                        if (annotation.getCustomData('relatedSnapshotId')) {
                            const docRef = doc(reviewIdentifiedBlockRef, annotation.getCustomData('relatedSnapshotId'))
                            await deleteDoc(docRef)
                        } else {

                            console.error('Snap shot is not found for TickMark annotation', annotation.Id)
                        }

                    } else if (action === 'add' && isTickMarkOpen) {
                        const rect = annotation.getRect()
                        const page = annotation.getPageNumber()

                        const docViewer = pdfInstance.Core.documentViewer as Core.DocumentViewer;

                        const text = await docViewer.getDocument().getTextByPageAndRect(page, rect)

                        handleTickMarkAnnotation({
                            text,
                            coords: [rect.x1, rect.y1, rect.getWidth(), rect.getHeight()],
                            page: page - 1,
                            annotation,
                            updateType: action,
                        })
                    }
                } else if (isSumSelect) {
                    if (action === 'delete') {
                        const annotation = annotations[0]

                        // Delete if there is a related block
                        if (annotation.getCustomData('relatedSnapshotId')) {
                            const docRef = doc(reviewIdentifiedBlockRef, annotation.getCustomData('relatedSnapshotId'))

                            await updateDoc(docRef, { rejected: true })
                        } else {
                            // For now case of deletion related annotations.
                            // Ignore for now.
                        }
                    } else {
                        if (isSumSelectOpen) {
                            // ;

                            // handleSumSelectAnnotation({
                            //     annotations: changedAnnotations,
                            //     updateType,
                            // })
                        } else {
                            //
                        }
                    }
                } else if(isValueIdentifier) {
                    if(action === 'add') {
                        await handleValueIdentifierAnnotation({
                            annotations,
                        })
                    }

                    return
                }
            }

            // Common annotations without active tool
            handleCommonAnnotation({
                annotations,
                updateType: action,
                currentStep,
            })
        })()
    }, [pdfInstance, handleTickMarkAnnotation, handleCommonAnnotation, currentStep])

    useEffect(() => {
        if (!pdfInstance) return

        const docViewer = pdfInstance.Core.documentViewer as Core.DocumentViewer
        const annotManager = docViewer.getAnnotationManager();

        // Unsubscribe from previous call listeners
        annotManager.addEventListener('annotationChanged', listenerCallback);

        const selectCallback = (annotations: Core.Annotations.Annotation[], action, ...rest) => {
            const anntVariant = annotations[0]?.getCustomData(CustomDataKey.annotationVariant)

            if(action === 'deselected') {
                setFocusedId(undefined)

                if(anntVariant === AnnotationVariant.moneyValue) {
                    // debugger
                    // setFocusedExtractedValueSnapId(undefined)
                }

                return
            } else if (action === 'selected') {
                if(anntVariant === AnnotationVariant.moneyValue || anntVariant === AnnotationVariant.moneyValueActive ) {
                    // annotations[0].setBorderStyle(undefined)
                    // annotations[0].Opacity = 0.6
                    // annotManager.redrawAnnotation(annotations[0])
                    
                    const snapId = annotations[0].getCustomData(CustomDataKey.relatedSnapshotId)
                
                    if(snapId?.length) {
                        setFocusedExtractedValueSnapId(snapId)
                    }
                } else {
                    // For all annotations other than money value
                    setFocusedId(annotations[0].Id.toString())
                    // Different type of the annotation was selected and we reset snapId for extracted value
                    setFocusedExtractedValueSnapId(undefined)
                }
            }
        }

        const annotClickCallback = (e) => {
            const annotation = annotManager.getAnnotationByMouseEvent(e);

            if (annotation) {
                const toolName = annotation.getCustomData('toolName')

                if (toolName === CustomToolNames.CrossLink) {
                    handleLinkAnnotationSelect(annotation)
                }
            }
        }

        annotManager.addEventListener('annotationSelected', selectCallback)

        docViewer.addEventListener('mouseLeftDown', annotClickCallback);

        return () => {
            annotManager.removeEventListener('annotationSelected', selectCallback)
            annotManager.removeEventListener('annotationChanged', listenerCallback);
            docViewer.removeEventListener('mouseLeftDown', annotClickCallback);
        }
    }, [pdfInstance, listenerCallback]);
}
