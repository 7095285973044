import React, { useContext, useState, useEffect } from 'react'

import { useAsyncEffect } from 'ahooks';
import { Flex } from 'antd';
import { useQueryParam } from 'use-query-params';

import { PdfViewerContext2 } from '@/App.tsx';
import { AuthData, AuthDataContext } from '@/components/containers/AuthContext';
import { StyledText } from '@/components/StyledText';
import { QUERY_PARAMS_CONFIG } from '@/config/queryParams.ts';
import { ReviewStepComment } from '@/firestore/api/reviewStepComment.ts';
import {
    ValueValidation, ValueValidationConfidence,
    useValueValidationQuery,
    VALUE_VALIDATION_COLOR,
} from '@/firestore/api/valueValidation.ts';
import { useViewerDocument } from '@/hooks/useViewerDocument.ts';
import { StepMessageItem } from '@/pages/ReviewPage';
import { useCreateAnnotations } from '@/utils/pdfViewer/createAnnotations.ts';
import { getAnnotationBySnapId } from '@/utils/pdfViewer/getAnnotationBySnapId.ts';
import { LocalErrorBoundary } from '@/widgets/CriticalErrorPlaceholder';
import { createExtractedValueAnnotation } from '@/widgets/PdfViewer2/PdfViewer2.utils.ts';

import { IndentifiedBlockItemProps } from './ExtractedBlockItem.types.ts'

export const getValidationValueConfColor = (valueValidationData: ValueValidation) => {
    const allConf = {
        ...valueValidationData.auto,
        ...valueValidationData.manual,
    }

    let conf: ValueValidationConfidence = 'notApplicable'

    const confAr = Object.entries(allConf)

    const hasValid = confAr.some(([type, confidence]) => confidence === 'valid')
    const hadInvalid = confAr.some(([type, confidence]) => confidence === 'invalid')
    const hasVerificationRequired = confAr.some(([type, confidence]) => confidence === 'verificationRequired')

    if(hasValid && !hasVerificationRequired && !hadInvalid) {
        conf = 'valid'
    } else if(hadInvalid) {
        conf = 'invalid'
    } else if(hasVerificationRequired) {
        conf = 'verificationRequired'
    }
            
    const confColor = VALUE_VALIDATION_COLOR[conf]

    return {
        color: confColor,
        conf,
    }
}

export const ExtractedBlockItem = (props: IndentifiedBlockItemProps) => {
    const { block, messages } = props
    const { annotationManager,documentViewer } = useViewerDocument()
    const { pdfInstance } = useContext(PdfViewerContext2)

    const [focusedSnapIdParam, setFocusedSnapIdParam] = useQueryParam(QUERY_PARAMS_CONFIG.FOCUSED_EXTRACTED_VALUE_SNAP_ID_QUERY_PARAM.key, QUERY_PARAMS_CONFIG.FOCUSED_EXTRACTED_VALUE_SNAP_ID_QUERY_PARAM.type)

    // const isFocused = focusedId?.length && (focusedId === annotation?.Id)

    const [confidenceColor, setConfidenceColor] = useState<string>()

    const { data: valueValidation } = useValueValidationQuery({
        filters: ['extractedValueId', '==', block.id as string],
    }, {
        enabled: !!block?.id,
    })

    useEffect(() => {
        if (!valueValidation?.[0]) return;

        const valueValidationData = valueValidation[0]
        const { color: confColor } = getValidationValueConfColor(valueValidationData)
        setConfidenceColor(confColor)
    }, [valueValidation]);

    const [relatedAnnotation, setRelatedAnnotation] = useState<Core.Annotations.Annotation>()

    useAsyncEffect(async () => {
        if(!annotationManager || !block.id) return
        const annotation = getAnnotationBySnapId(annotationManager, block.id)
        setRelatedAnnotation(annotation)
    }, [block.id, annotationManager])
    
    const handleDelete = async (event) => {
        event.stopPropagation()

        try {
            // const annot = annotationManager.getAnnotationById(blockData.annotaionId || annotation.Id)
            // annotationManager.deleteAnnotations([annot])
            //
            // await updateDoc(block.ref, { rejected: true })
            //
            // if(!annot) {
            //
            //     console.error('Annotation not found for the block during deletion')
            // }
        } catch (error) {
            console.error('Error updating block', error)
        }
    }

    const blockData = block

    const createAnnotations = useCreateAnnotations()
    
    const authData = useContext<AuthData>(AuthDataContext)

    return (
        <Flex vertical={true} gap={8}>
            <Flex
                key={blockData.companyId}
                justify='space-between'
                align='center'
                style={{ cursor: 'pointer' }}
                onClick={async () => {
                    if(!annotationManager || !pdfInstance) return
                    
                    let annotation = getAnnotationBySnapId(annotationManager, block.id)
                    
                    setFocusedSnapIdParam(block.id)

                    if(!annotation && createAnnotations) {
                        annotation = await createExtractedValueAnnotation({
                            pdfInstance,
                            moneyValue: block,
                            annotationManager,
                            createAnnotations,
                            authData,
                        })
                    }
                    
                    if(!annotation) return;
                    
                    annotationManager?.jumpToAnnotation(annotation)
                }}
            >
                <Flex
                    align='center'
                    gap={8}
                >
                    <div
                        style={{ height: 14,
                            width: 14,
                            borderRadius: '20%',
                            background: confidenceColor,
                            flexShrink: 0 }}
                    />
                    <StyledText>{[].concat(blockData.originalValue).join(' ')}</StyledText>
                </Flex>
                {/*<Flex*/}
                {/*    align='center'*/}
                {/*>*/}
                {/*    <Tooltip placement='left' title='Area was identified incorrectly'>*/}
                {/*        <Button*/}
                {/*            type='link'*/}
                {/*            size='small'*/}
                {/*            onClick={handleDelete}*/}
                {/*            icon={<CloseOutlined/>}*/}
                {/*            style={{ color: '#bdb4b4' }}*/}
                {/*        />*/}
                {/*    </Tooltip>*/}
                {/*</Flex>*/}
            </Flex>
            
            <Flex vertical gap={16}>
                {messages?.map((item, index) => (
                    <LocalErrorBoundary noContent key={item.id}>
                        <StepMessageItem
                            data={item.data() as ReviewStepComment}
                            id={item.id}
                            blockAnnotation={relatedAnnotation}
                            blockCoordinates={[blockData.coordinates?.x0, blockData.coordinates?.y0, blockData.coordinates?.width, blockData.coordinates?.height]}
                        />
                    </LocalErrorBoundary>
                ))}
            </Flex>
        </Flex>
    )
}
