import React, { CSSProperties, ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react'

import {
    CalculatorOutlined,
    GatewayOutlined,
    ThunderboltFilled,
} from '@ant-design/icons';
import { useAsyncEffect, useUpdateEffect, useDebounceFn, useWhyDidYouUpdate } from 'ahooks';
import { Button, CollapseProps, Flex, Result, Typography, Tooltip, Spin } from 'antd';
import { Collapse } from 'antd/lib';
import { query, where, and } from 'firebase/firestore';
import { useCollection, useCollectionData } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';

import { AuthData, AuthDataContext } from '@/components/containers/AuthContext';
import { COLORS } from '@/constants/colors.ts';
import { useMathValidationQuery } from '@/firestore/api/mathValidation';
import {
    useReportExtractedValuesByIdQuery,
    useReportExtractedValuesQuery,
} from '@/firestore/api/reportExtractedValues.ts';
import { resolvedCommentRef } from '@/firestore/api/resolvedComments.ts';
import { ReviewStepComment, reviewStepCommentRef } from '@/firestore/api/reviewStepComment.ts';
import {
    useValueValidationCreateMutation,
    useValueValidationUpdateMutation,
    useValueValidationQuery, ValueValidation, ValueValidationConfidence,
    ValueValidationType, valueValidationTypes, VALIDATION_BY_VALUE_TYPE,
} from '@/firestore/api/valueValidation.ts';
import { useRenderMeter } from '@/hooks/renderMeter.ts';
import { useFocusedValueId } from '@/hooks/useFocusedValueId';
import { useMagicTools } from '@/hooks/useMagicTools';
import { useViewerDocument } from '@/hooks/useViewerDocument.ts';
import {
    ConfidencePicker,
    IcValidatioin,
    StepMessageItem,
} from '@/pages/ReviewPage';
import {
    getValidationValueConfColor,
} from '@/pages/ReviewPage/GuidePanel/Steps/StepContent/ExtractedValuesBlock/ExtractedBlockItem';
import { getAnnotationBySnapId } from '@/utils/pdfViewer/getAnnotationBySnapId.ts';
import { IDENTIFY_VALUE_QUERY_CONFIG, SUM_SELECT_QUERY_PARAM } from '@/widgets/MagicButtons/MagicButtons.constants.ts';
import { SUM_SELECT_COMPARE_TO_VALUE_QUERY_CONFIG } from '@/widgets/SumSelect/SumSelect.contants.ts';

import { AnnotationContextProps } from './AnnotationContext.types'
import { MathValidation } from './MathValidation/MathValidation';

export const AnnotationContext = (props: AnnotationContextProps) => {
    const { annotationManager, pdfInstance } = useViewerDocument()
    const { selectedExtractedValSnapId } = useFocusedValueId()
    
    const confPickerPrevState = useRef()

    const [identifyValOpen, setIdentifyValOpen] = useQueryParam(IDENTIFY_VALUE_QUERY_CONFIG.name, IDENTIFY_VALUE_QUERY_CONFIG.type)

    const [confPickerState, setConfPickerState] = useState<Partial<Record<ValueValidationType, ValueValidationConfidence>>>({})
    
    useEffect(() => {
        const changedItem = Object.entries(confPickerState).find(([type, state]) => {
            const prevState = confPickerPrevState.current?.[type]
            return prevState !== state
        })
        
        if (changedItem && changedItem[1] === 'notApplicable') {
            setActiveKeys(prev => prev.filter(key => key !== changedItem[0]))
        }
        
        confPickerPrevState.current = confPickerState
    }, [confPickerState]);
    
    const authData = useContext<AuthData>(AuthDataContext)
    const { id: docId } = useParams()

    const [value, setValue] = useState()
    const [isNumber, setIsNumber] = useState(false)
    const [focusedAnnotationObj, setFocusedAnnotObj] = useState<pdfInstance.Core.Annotations.Annotation | undefined>()

    useRenderMeter()
    
    const reportExtractedValuesByIdQuery = useReportExtractedValuesByIdQuery(selectedExtractedValSnapId, { enabled: !!selectedExtractedValSnapId })

    const reportExtractedValuesQuery = useReportExtractedValuesQuery(
        {
            filters: ['and',
                ['companyId', '==', authData.company.id],
                ['reportId', '==', docId as string],
            ],
        },
        {
            enabled: Boolean(docId && authData.company.id),
        },
    );

    const [reviewStepCommentSnap, reviewStepCommentLoading] = useCollection<ReviewStepComment>(query(
        reviewStepCommentRef,
        // None needed just for handling empty value correctly
        and(
            where('extractedValueId', '==', selectedExtractedValSnapId || 'none'),
        ),
    ))

    const [resolvedCommentsData, resolvedCommentsLoading] = useCollectionData(
        query(resolvedCommentRef, where('reportOnReviewId', '==', docId)),
    )

    const filteredMessageStaps = useMemo(() => reviewStepCommentSnap?.docs?.filter(commentDoc => {
        // Check if the comment has been resolved
        const isResolved = resolvedCommentsData?.some(
            resolvedComment =>
                resolvedComment.commentId === commentDoc.id &&
                ( resolvedComment.status === 'rejected' || resolvedComment.status === 'applied'),
        );
        // Only include comments that haven't been resolved
        return !isResolved;
    }),
    [reviewStepCommentSnap])

    const mathValidationQuery = useMathValidationQuery(
        {
            filters: [
                'and',
                ['extractedValueId', '==', selectedExtractedValSnapId || 'none'],
                ['type', '==', 'sum'],
        
            ],
        },
    );

    const mathValidationData = mathValidationQuery.data || []

    useEffect(() => {
        if(!selectedExtractedValSnapId || !annotationManager) return

        const annot = getAnnotationBySnapId(annotationManager, selectedExtractedValSnapId)

        if(annot) {
            setFocusedAnnotObj(annot)
        } else {
            // Fixme: Create here an annotation (it propably is not created because of laze loading)
            // console.error('Annotation not found', annotationId)
        }
    }, [selectedExtractedValSnapId, annotationManager]);

    useEffect(() => {
        if (!annotationManager || !selectedExtractedValSnapId || reportExtractedValuesByIdQuery.isLoading) return

        const data = reportExtractedValuesByIdQuery.data

        if (!data) return;

        const normValue = data.originalValue

        setValue(normValue)

        const isNumberType = data.normalizedValue !== undefined && data.normalizedValue !== null && (data.type === 'number' || data.type === 'money')

        setIsNumber(isNumberType)
    }, [annotationManager, selectedExtractedValSnapId, reportExtractedValuesByIdQuery.dataUpdatedAt, reportExtractedValuesByIdQuery.isLoading]);

    const itemStyle : CSSProperties = {
        borderRadius: 0,
        cursor: 'default',
    }

    const [sumSelectOpen] = useQueryParam(SUM_SELECT_QUERY_PARAM.name, SUM_SELECT_QUERY_PARAM.type)
    const [, setSumSelectTotalMode] = useQueryParam<boolean>(SUM_SELECT_COMPARE_TO_VALUE_QUERY_CONFIG.name, SUM_SELECT_COMPARE_TO_VALUE_QUERY_CONFIG.type)

    const messagesByTick = useMemo(() => valueValidationTypes.reduce((acc, type) => {
        const messages = filteredMessageStaps?.filter(doc => doc.data().validationType === type)
        if(messages?.length) {
            acc[type] = messages
        }
        return acc
    }, {})
    , [valueValidationTypes, filteredMessageStaps])

    const messagesValidationType = useMemo(() => filteredMessageStaps?.filter(doc => !doc.data().validationType), [filteredMessageStaps])
    messagesByTick['other'] = messagesValidationType

    const isMaLoading = reportExtractedValuesByIdQuery.isLoading || reviewStepCommentLoading || reportExtractedValuesQuery.isLoading || mathValidationQuery.isLoading || resolvedCommentsLoading

    const { handleTurnOnTool } = useMagicTools()

    const haveInvalidMathValidation = mathValidationData.some(el => !el.valid)

    const handleSumSelectOpen = () => {
        handleTurnOnTool(SUM_SELECT_QUERY_PARAM.name)
        setSumSelectTotalMode(true)
    }
         
    const collapseContent: Record<ValueValidationType, ReactNode> = useMemo(() => ({
        ma: isMaLoading ? <Spin size='default'/> : (isNumber) ? (
            <Flex vertical gap={8}>
                {mathValidationData.length ? (
                    <Flex gap={4} justify='space-between'>
                        <Flex gap={4}>
                            <Tooltip placement='left' title='Results of the validation generated by the totals validation tool'><CalculatorOutlined style={{ color: '#13C2C2' }}/></Tooltip>
                            <Typography.Text strong>Totals Validation</Typography.Text>
                        </Flex>
                        {haveInvalidMathValidation && (
                            <Button
                                size='small' 
                                type='link'
                                icon={<CalculatorOutlined/>}
                                onClick={handleSumSelectOpen}
                            >Update with SumSelect</Button>
                        )}
                    </Flex>
                ) : null}
                {mathValidationData.map((doc) => (
                    <MathValidation key={doc.id} validationSnap={doc} allComponents={reportExtractedValuesQuery.data}/>
                ))}
                {(!mathValidationData?.length && !messagesByTick['ma']?.length) && (
                    <Flex justify='center'>
                        <Button
                            type={sumSelectOpen ? 'primary' : 'default'}
                            onClick={handleSumSelectOpen}
                            icon={<CalculatorOutlined/>}
                        >
                            Validate With SumSelect
                        </Button>
                    </Flex>
                )}
            </Flex>
        ) : null,
        py: null,
        ic: <IcValidatioin/>,
    }), [isMaLoading, mathValidationData, messagesByTick, reportExtractedValuesQuery.dataUpdatedAt, haveInvalidMathValidation])

    const valueValidationCreateMutation = useValueValidationCreateMutation()

    const valueValidationQuery = useValueValidationQuery({
        filters: ['extractedValueId', '==', selectedExtractedValSnapId],
    }, {
        enabled: Boolean(selectedExtractedValSnapId),
    })

    const valueValidationQueryData = valueValidationQuery.data?.[0]

    // Reset local state on values id change
    useEffect(() => {
        setConfPickerState({})
    }, [selectedExtractedValSnapId]);

    // Queue to store pending updates by annotation ID
    const updateQueue = useRef(new Map<string, {
        promise: Promise<any>;
        state: any;
        timestamp: number;
    }>());

    const processUpdate = async (annotationId: string, newState: any) => {
        const currentQueueItem = updateQueue.current.get(annotationId);

        // If there's a more recent update for this annotation, skip this one
        if (currentQueueItem && currentQueueItem.timestamp > Date.now()) {
            return;
        }

        if (!valueValidationQueryData?.id) {
            const newItemData: ValueValidation = {
                companyId: authData.company.id,
                reportId: docId as string,
                extractedValueId: annotationId,
                manual: {
                    ...newState,
                },
            }

            await valueValidationCreateMutation.mutateAsync({
                data: newItemData,
            });
        } else {
            const currentManual = valueValidationQueryData?.manual || {};
            const updatedManual = {
                ...currentManual,
                ...newState,
            }

            await valueValidationUpdateMutation.mutateAsync({
                id: valueValidationQueryData.id as string,
                data: {
                    manual: updatedManual,
                },
            });
        }

        // Remove from queue after successful processing
        updateQueue.current.delete(annotationId);
    };

    const { run: debouncedUpdate } = useDebounceFn(
        async (annotationId: string, newState: any) => {
            const queueItem = {
                promise: processUpdate(annotationId, newState),
                state: newState,
                timestamp: Date.now(),
            };

            // Add to queue
            updateQueue.current.set(annotationId, queueItem);

            try {
                await queueItem.promise;
            } catch (error) {
                console.error('Failed to process update:', error);
            }
        },
        { wait: 0 },
    );

    useUpdateEffect(() => {
        if (reportExtractedValuesByIdQuery.isLoading || valueValidationQuery.isLoading) return;

        // State reset case
        if (Object.keys(confPickerState).length === 0) return;

        if (!selectedExtractedValSnapId) return;

        const currentManual = valueValidationQueryData?.manual || {};

        const manualState = {
            ...currentManual,
            ...confPickerState,
        }

        debouncedUpdate(selectedExtractedValSnapId, confPickerState);
    }, [confPickerState]);

    const getMessages = (messages: ReviewStepComment[]) => {
        const res = messages?.map((message) => {
            return (
                <StepMessageItem
                    data={message.data() as ReviewStepComment}
                    id={message.id}
                    blockAnnotation={focusedAnnotationObj}
                />
            ) || []
        })

        return res
    }

    const valueData = reportExtractedValuesByIdQuery.data

    const validationTypesByValueType = useMemo(() => {
        let res = VALIDATION_BY_VALUE_TYPE[valueData?.type] || valueValidationTypes
        
        // Exclude ma if values is not a number
        const excludeMa = valueData?.normalizedValue === null || isNaN(valueData?.normalizedValue)

        if(excludeMa) {
            res = res.filter(el => el !== 'ma')
        }

        const excludeOther = !messagesValidationType?.length

        if(excludeOther) {
            res = res.filter(el => el !== 'other')
        }
        
        return res
    }, [valueData?.type, messagesValidationType, reportExtractedValuesByIdQuery.dataUpdatedAt])
    
    const valueValidationUpdateMutation = useValueValidationUpdateMutation()

    const items: CollapseProps['items'] = useMemo(() => validationTypesByValueType.map(type => {
        const haveContent = messagesByTick?.[type]?.length || collapseContent[type]

        const item: CollapseProps['items'] = {
            key: type,
            style: itemStyle,
            destroyInactivePanel: true,
            // collapsible: !haveContent ? 'disabled' : 'icon',
            label: (
                <ConfidencePicker
                    state={confPickerState[type]}
                    onChange={(data) => {
                        debugger
                        setConfPickerState(prev => ({
                            ...prev,
                            [data.type]: data.state,
                        }))
                    }}
                    valueValidationUpdateMutation={valueValidationUpdateMutation}
                    type={type}
                    selectedExtractedValSnapId={selectedExtractedValSnapId}
                    focusedValueSnap={reportExtractedValuesByIdQuery.data}
                />
            ),
            children:
           haveContent ? (
               <Flex gap={4} vertical>
                   {collapseContent[type]}
                   {messagesByTick[type]?.length ? (
                       <Flex gap={4}>
                           <Tooltip placement='left' title='Items generated automatically for this step'><ThunderboltFilled style={{ color: '#13C2C2' }}/></Tooltip>
                           <Typography.Text strong>Suggested Comments</Typography.Text>
                       </Flex>
                   ) : null}
                   {getMessages(messagesByTick[type])}
               </Flex>
           ) : null,
        }

        return item
    }), [
        validationTypesByValueType,
        messagesByTick,
        confPickerState,
        selectedExtractedValSnapId,
        reportExtractedValuesByIdQuery.dataUpdatedAt,
        collapseContent,
    ]);
    
    const [confColor, setConfColor] = useState(COLORS.main)

    useAsyncEffect(async () => {
        if(!selectedExtractedValSnapId || valueValidationQuery.isLoading) return

        const valueValidationData = valueValidationQuery.data?.[0]

        if(!valueValidationData) return

        const { color: confColor } = getValidationValueConfColor(valueValidationData)

        setConfColor(confColor)
    }, [selectedExtractedValSnapId,valueValidationQuery.dataUpdatedAt]);

    const [activeKeys, setActiveKeys] = useState<ValueValidationType[]>(['ma'])
    
    useEffect(() => {
        if (reportExtractedValuesByIdQuery.isLoading || reviewStepCommentLoading || mathValidationQuery.isLoading) return;

        const itemsWithContent = validationTypesByValueType.filter(type => {
            const haveMessages = messagesByTick?.[type]?.length;
            const haveContent = haveMessages || Boolean(collapseContent[type]);
            return haveContent;
        });

        setActiveKeys(itemsWithContent);
    }, [
        reportExtractedValuesByIdQuery.isLoading,
        reviewStepCommentLoading,
        mathValidationQuery.isLoading,
        messagesByTick,
        validationTypesByValueType,
    ]);

    console.log('kek')
    useWhyDidYouUpdate('Context', {
        activeKeys,
        items,
        selectedExtractedValSnapId,
        messagesByTick,
        validationTypesByValueType,
        reportExtractedValuesByIdQuery: reportExtractedValuesByIdQuery.dataUpdatedAt,
    })

    return (
        <Flex
            style={{
                flexGrow: 1,
                alignItems: (!selectedExtractedValSnapId) ? 'center' : 'flex-start',
                justifyContent: (!selectedExtractedValSnapId) ? 'center' : 'flex-start',
                paddingBottom: 16,
                overflowY: 'auto',
            }}
        >
            {(selectedExtractedValSnapId) ? (
                <Flex vertical style={{ width: '100%' }}>
                    <Collapse
                        activeKey={activeKeys}
                        onChange={(k) => {
                            debugger
                            setActiveKeys(k)
                        }}
                        accordion={false}
                        items={items}
                        style={{
                            borderRadius: 0,
                            borderLeft: 'none',
                            borderRight: 'none',
                        }}
                    />
                </Flex>
            ) : (
                <Result
                    icon={<GatewayOutlined/>}
                    title='Select a value to apply validation'
                    extra={(
                        <Button
                            onClick={() => {
                                setIdentifyValOpen(true)
                            }}
                        >Create a New</Button>
                    )}
                />
            )}
        </Flex>
    )
}
