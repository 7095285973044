import React, { useState } from 'react'

import {
    CheckOutlined,
    CloseOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons';
import { Button, Flex, Spin, Tag, Tooltip, Typography } from 'antd';
import { and, query, where } from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';

import { QUERY_PARAMS_CONFIG } from '@/config/queryParams.ts';
import { COLORS } from '@/constants/colors.ts';
import {
    useReportExtractedValuesByIdQuery,
} from '@/firestore/api/reportExtractedValues.ts';
import { ReviewLinkedObjects, reviewLinkedObjectsRef } from '@/firestore/api/reviewLinkedObjects.ts';
import {
    useValueValidationQuery,
    ValueValidationType,
    ValueValidationConfidence, HINT_MESSAGE_BY_VALUE_VALIDATION_TYPE, getAIValidationHint,
} from '@/firestore/api/valueValidation.ts';

import { ConfidencePickerProps } from './ConfidencePicker.types'

export const ConfidencePicker = (props: ConfidencePickerProps) => {
    const { type, valueValidationUpdateMutation, selectedExtractedValSnapId, onChange, state } = props

    const { id: docId } = useParams()

    const [focusedSnapIdParam] = useQueryParam(QUERY_PARAMS_CONFIG.FOCUSED_EXTRACTED_VALUE_SNAP_ID_QUERY_PARAM.key, QUERY_PARAMS_CONFIG.FOCUSED_EXTRACTED_VALUE_SNAP_ID_QUERY_PARAM.type)
    
    const { data: valuesValidation } = useValueValidationQuery({
        filters: ['extractedValueId', '==', selectedExtractedValSnapId as string],
    }, {
        enabled: !!selectedExtractedValSnapId,
    })

    const reportExtractedValuesByIdQuery = useReportExtractedValuesByIdQuery(selectedExtractedValSnapId, { enabled: !!selectedExtractedValSnapId })

    const [reivewLinkObjectsSnap, revewLinkObjectsLoading] = useCollection<ReviewLinkedObjects>(query(reviewLinkedObjectsRef, and(
        where('reportId', '==', docId),
        where('type', 'in', ['money', 'text']),
    )))

    const linksRelatedToFocusedSnap = reivewLinkObjectsSnap?.docs.find(doc => doc.data().linksGroup.some(link => link.extractedValueId === focusedSnapIdParam))

    const relatedLinkIds = linksRelatedToFocusedSnap?.data()?.linksGroup
        ?.filter(el => !el.rejected)
        ?.map((link) => link.extractedValueId) || []

    const valuesValidationQuery = useValueValidationQuery({
        filters: ['extractedValueId', 'in', relatedLinkIds as string[]],
    }, {
        enabled: Array.isArray(relatedLinkIds) && relatedLinkIds.length > 0,
    })
    
    const valueValidationSnap = valuesValidation?.[0]

    const setManualValidation = (type: ValueValidationType) => async (conf: ValueValidationConfidence) => {
        debugger
        if(!reportExtractedValuesByIdQuery.data || reportExtractedValuesByIdQuery.isLoading) return
        if (conf === state) return;
        debugger

        onChange({
            type,
            state: conf,
        })
        
        // const annotationsList = annotationManager.getAnnotationsList()

        try {
            // FIXME: Not so good place for this logic. Better to keep in IC related component
            /**
             * Find all related link and apply same validation status for them
             */
            if (type === 'ic') {
                const promises = (valuesValidationQuery.data || []).map(data => {
                    return valueValidationUpdateMutation.mutateAsync({
                        id: data.id,
                        data: {
                            manual: {
                                ...data.manual,
                                [type]: conf,
                            },
                        },
                    })
                })

                await Promise.all(promises)
            }
        } catch (error) {
            debugger
            console.error('Error updating validation:', error);
        }
    }

    const conf = state || valueValidationSnap?.manual?.[type] || valueValidationSnap?.auto?.[type];
    const autoConfApplied = !state && !valueValidationSnap?.manual?.[type] && !!valueValidationSnap?.auto?.[type]

    const manualActionRequired = conf === 'verificationRequired'

    const hintContent = reportExtractedValuesByIdQuery.data && HINT_MESSAGE_BY_VALUE_VALIDATION_TYPE[type]?.(reportExtractedValuesByIdQuery?.data?.type)
    const notApplicable = state === 'notApplicable'
    
    const [isHovered, setIsHovered] = useState(false)

    return (
        <Flex
            gap={16}
            onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
            }}
            align='center'
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={{
                opacity: notApplicable ? 0.5 : 1,
            }}
        >
            <Typography.Title level={3} style={{ margin: 0 }}>
                {type.toUpperCase()}
            </Typography.Title>
            {hintContent && isHovered && (
                <Tooltip title={hintContent}>
                    <QuestionCircleOutlined
                        style={{
                            color: COLORS.gray,
                            fontSize: 20,
                            marginTop: 2,
                        }}
                    />
                </Tooltip>
            )}
            <Flex
                style={{ flexGrow: 1,
                    justifyContent: 'flex-end' }}
                gap={12}
            >
                <Flex gap={12} align='center'>
                    {reportExtractedValuesByIdQuery.isLoading && <Spin size='small'/>}
                    {autoConfApplied && (
                        <Tooltip
                            title={getAIValidationHint({
                                validationType: type ,
                                conf: conf,
                                valueType: reportExtractedValuesByIdQuery.data?.type,
                                extractedValue: reportExtractedValuesByIdQuery.data,
                            })}
                        >
                            <Tag
                                color={manualActionRequired ? 'orange' : 'geekblue'}
                                style={{ width: 'fit-content' }}
                            >
                                 Nexly AI
                            </Tag>
                        </Tooltip>
                    )}
                    {/*<Tooltip*/}
                    {/*    title={`Mark ${type.toUpperCase()} check result`}*/}
                    {/*    placement='left'*/}
                    {/*    style={{*/}
                    {/*        opacity: reportExtractedValuesByIdQuery.isLoading ? 0.5 : 1,*/}
                    {/*    }}*/}
                    {/*>*/}
                    <Flex gap={4}>
                        {/*<Tooltip title={`${type.toUpperCase()} check was successful for this element`} placement='left'>*/}
                        {/*    <Flex*/}
                        {/*        style={{ */}
                        {/*            height: 32, width: 32, borderRadius: '20%', background: 'yellow', flexShrink: 0,*/}
                        {/*            opacity: conf === 'verificationRequired' ? 1 : 0.3,*/}
                        {/*            display: 'flex',*/}
                        {/*            cursor: 'pointer',*/}
                        {/*        }}*/}
                        {/*        align='center'*/}
                        {/*        justify='center'*/}
                        {/*        onClick={() => setManualValidation(type)('verificationRequired')}*/}
                        {/*    >*/}
                        {/*        {*/}
                        {/*            conf === 'valid' && <CheckOutlined style={{ color: 'white' }}/>*/}
                        {/*        }*/}
                        {/*    </Flex>*/}
                        {/*</Tooltip>*/}
                        <Flex
                            style={{
                                height: 32,
                                width: 32,
                                borderRadius: '20%',
                                background: COLORS.green,
                                flexShrink: 0,
                                opacity: conf === 'valid' ? 1 : 0.3,
                                display: 'flex',
                                cursor: 'pointer',
                            }}
                            align='center'
                            justify='center'
                            onClick={() => {
                                setManualValidation(type)('valid')
                            }}
                        >
                            {
                                conf === 'valid' && <CheckOutlined style={{ color: 'white' }}/>
                            }
                        </Flex>
                        <Flex
                            align='center'
                            justify='center'
                            onClick={() => {
                                setManualValidation(type)('invalid')
                            }}
                            style={{
                                height: 32,
                                width: 32,
                                borderRadius: '20%',
                                background: COLORS.red,
                                flexShrink: 0,
                                opacity: conf === 'invalid' ? 1 : 0.3,
                                display: 'flex',
                                cursor: 'pointer',
                            }}
                        >
                            {
                                conf === 'invalid' && <CheckOutlined style={{ color: 'white' }}/>
                            }
                        </Flex>
                    </Flex>
                    {/*</Tooltip>*/}

                    {/*<Tooltip title='Validation type is not applicable' placement='left'>*/}
                    <Button
                        onClick={() => {
                            setManualValidation(type)('notApplicable')
                        }}
                        icon={<CloseOutlined/>}
                    />
                    {/*</Tooltip>*/}
                </Flex>
            </Flex>
        </Flex>
    )
}
