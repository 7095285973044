import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import 'pdfjs-dist/web/pdf_viewer.css';
import WebViewer, { Core, WebViewerInstance } from '@pdftron/webviewer';
import { and, doc, query, where } from 'firebase/firestore';
import { useCollection, useDocument } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router-dom';
import { useQueryParam, BooleanParam } from 'use-query-params';

import { firebaseDownloadUrl } from '@/api/utils/firebase/firebaseDownloadUrl.ts';
import { PdfContext, PdfViewerContext2 } from '@/App.tsx';
import { AuthData, AuthDataContext } from '@/components/containers/AuthContext';
import { QUERY_PARAMS_CONFIG } from '@/config/queryParams.ts';
import { CONFIG } from '@/config.ts';
import { ACTIVE_MENU_GROUP_LOCAL_STORAGE_KEY } from '@/constants/localStorageKeys.ts';
import { CustomDataKey } from '@/constants/pdfViewer/customDataKey';
import { useAnnotationReplyQuery } from '@/firestore/api/annotationReply.ts';
import { InputFile, useInputFilesQuery } from '@/firestore/api/inputFiles.ts';
import {
    ReportExtractedVlueTypesToFetch,
    useReportExtractedValuesQuery,
} from '@/firestore/api/reportExtractedValues.ts';
import { ReportReview, reportReviewRef } from '@/firestore/api/reportReview.ts';
import { ReviewLinkedObjects, reviewLinkedObjectsRef } from '@/firestore/api/reviewLinkedObjects.ts';
import {
    ValueValidation,
    ValueValidationConfidence, ValueValidationType, valueValidationRef,
} from '@/firestore/api/valueValidation.ts';
import { useFpsMeter } from '@/hooks/fpsMeter.ts';
import { useRenderMeter } from '@/hooks/renderMeter.ts';
import { useBoolTime } from '@/hooks/useBoolTime.ts';
import { useCurrentPage } from '@/hooks/useCurrentPage.ts';
import { useFocusedValueId } from '@/hooks/useFocusedValueId.ts';
import { useIsScrolling } from '@/hooks/useIsScrolling.ts';
import { useLocalStorage } from '@/hooks/useLocalStorage.ts';
import { usePerformanceTrace } from '@/hooks/usePerformanceTrace';
import { useViewerDocument } from '@/hooks/useViewerDocument.ts';
import { calculateRectIntersection } from '@/utils/calculateRectIntersection.ts';
import { createRange } from '@/utils/createRange.ts';
import { useFeatureOn } from '@/utils/isFeatureOn.ts';
import { useCreateAnnotations } from '@/utils/pdfViewer/createAnnotations.ts';
import { hideBySnapId } from '@/utils/pdfViewer/hideBySnapId.ts';
import { AI_CHAT_QUERY_CONFIG } from '@/widgets/MagicButtons/MagicButtons.constants.ts';
import { ACTIVE_IC_VALUE_QUERY_PARAM } from '@/widgets/MoneyValuesNavigator/MoneyValuesNavigator.constants.ts';
import {
    useAnnotStylesMofier,
    useApplyAnnotations, useReplyAnnotationHandler,
    useTableDebug,
    useTableOfContentDebug,
} from '@/widgets/PdfViewer2/PdfViewer2.hooks.ts';
import { AnnotationVariant, PdfViewer2Props } from '@/widgets/PdfViewer2/PdfViewer2.types.ts';
import {
    createAnnotation,
    createExtractedValueAnnotation,
    useUpdateAnnotationTicks,
} from '@/widgets/PdfViewer2/PdfViewer2.utils.ts';
import { useAnnotationsListener } from '@/widgets/PdfViewer2/useAnnotationsListener.ts';
import { useToolsListener } from '@/widgets/PdfViewer2/useToolsListener.ts';

import { useAsyncEffect } from 'ahooks';
import { Flex, Spin } from 'antd';
import { useVeltClient } from '@veltdev/react';

// Rename to annot type
export const enum CustomToolNames {
    TickMark = 'TickMark',
    SumSelect = 'SumSelect',
    CrossLink = 'CrossLink',
    ValueIdentify = 'ValueIdentify',
}

const libVersion = '10.11.1';
const VIEWER_LIB_STATIC_URL = `${window.location.protocol}//${window.location.host}/pdf-viewer/${libVersion}`;

interface StoredFile {
    id: string;
    blob: Blob;
    timestamp: number;
}

export const getConf = (valueValidation: ValueValidation, type: ValueValidationType): ValueValidationConfidence => valueValidation['manual']?.[type] || valueValidation['auto']?.[type]

/**
 * FIXME:
 * - Limit right click elements
 *
 * @todo: Wrap with 'Sentry.profiler' for performance monitoring
 *
 * LINKS:
 * 3 popup types: https://docs.apryse.com/documentation/web/guides/customizing-popup/
 */
export const PdfViewer2 = (props: PdfViewer2Props) => {
    const { fileUrl } = props;

    const { id: docId } = useParams()

    const { annotationManager, documentViewer, pdfDocument } = useViewerDocument()
    const { pdfInstance, setPdfInstance } = useContext<PdfContext>(PdfViewerContext2)

    // Less than 80 generates too much toggles and rerenders as a result
    const isScrolling = useIsScrolling(pdfInstance, 80);

    useFpsMeter(isScrolling);
    
    const authData = useContext<AuthData>(AuthDataContext)

    const [activeMoneyValues] = useQueryParam(ACTIVE_IC_VALUE_QUERY_PARAM.name, ACTIVE_IC_VALUE_QUERY_PARAM.type)

    const [reviewLinkObjectsSnap, revewLinkObjectsLoading] = useCollection<ReviewLinkedObjects>(query(reviewLinkedObjectsRef, and(
        where('reportId', '==', docId),
        where('type', 'in', ['ecdfNoteLink']),
    )))
    
    const [tab, setTab] = useQueryParam(QUERY_PARAMS_CONFIG.TAB.key, QUERY_PARAMS_CONFIG.TAB.type);

    const ananotationReplyOnSnapQuery = useAnnotationReplyQuery({
        filters: [
            'and',
            ['reportId', '==', docId as string],
            ['inputFileId', '==', tab as string],
        ],
    })
    
    const { updateAnnotationTicks } = useUpdateAnnotationTicks()

    // const valueValidationQuery = useValueValidationQuery(
    //     // {
    //     // FIXME: Wrong id applied to the report. Waiting fix in KAN-482
    //     // filters: [ 'reportId', '==', docId as string],
    //     // }
    // )

    const valueValidationSnap = useCollection<ValueValidation>(query(
        valueValidationRef,
        where('reportId', '==', docId),
    ))

    const valueValidationQuery = useMemo(() => {
        const [snap, loading] = valueValidationSnap;
        return {
            data: snap?.docs?.map((doc) => ({ id: doc.id,
                ...doc.data() })) || [] as ValueValidation[],
            isLoading: loading,
            dataUpdatedAt: new Date().getTime(),
        };
    }, [valueValidationSnap[1], valueValidationSnap[0]?.docs.map(doc => doc.id).join(), valueValidationSnap[0]?.docs.map(doc => JSON.stringify(doc.data().manual || {})).join(', ')]);
    
    const page = useCurrentPage()

    useAnnotStylesMofier()
    useReplyAnnotationHandler()

    const reportExtractedValuesQuery = useReportExtractedValuesQuery({
        filters: [
            'and',
            ['reportId', '==', docId as string],
            ['type', 'in', ReportExtractedVlueTypesToFetch],
        ],
    })

    useEffect(() => {
        setAppliedAnnotationIds(new Set())
    }, [page, reportExtractedValuesQuery.dataUpdatedAt, valueValidationQuery.dataUpdatedAt])

    const [reportSnapshot, reportSnapshotLoading] = useDocument<ReportReview>(doc(reportReviewRef, docId))

    const reportData = reportSnapshot?.data()
    const reviewInProgress = !(reportData?.reviewStatus !== 'inProgress')

    // Triggers to reset cursor
    useEffect(() => {
        if (page) {
            setAppliedAnnotationIds(new Set())
        }
    }, [page, tab, reviewInProgress])

    const instansInited = useRef(false);
    const viewer = useRef(null);
    const prefPagesApplied = useRef<number[]>([]);

    useToolsListener()
    useAnnotationsListener({
        reportSnapshot,
    })

    const [annotationsReady, setAnnotationsReady] = useState(false)

    useAsyncEffect(async () => {
        if (!documentViewer) return

        // Supposedly it's the beset time when we can start draw annotatations
        documentViewer.getAnnotationsLoadedPromise().then(() => {
            setAnnotationsReady(true)
        }).catch((e) => {
            console.error('Error loading annotations', e)
        })
    }, [documentViewer])

    const addCustomTools = useCallback((instance: WebViewerInstance, docViewer: Core.DocumentViewer) => {

    }, [])

    const Feature = pdfInstance?.UI.Feature;

    useApplyAnnotations({})

    useTableDebug()
    useTableOfContentDebug()

    /**
     * Apply links ecdf-notes
     */
    useAsyncEffect(async () => {
        if (!reviewLinkObjectsSnap || !annotationManager || revewLinkObjectsLoading || !pdfInstance || !annotationsReady || reportExtractedValuesQuery.isLoading) return
        if (reportData?.reviewStatus !== 'inProgress') return

        const filtered = reviewLinkObjectsSnap.docs

        const annotationsList = annotationManager.getAnnotationsList()

        for (const linkObject of filtered) {
            const linkData = linkObject.data()

            const groupUniqueKey = linkObject.id

            for (let i = 0; i < linkData.linksGroup.length; i++) {
                const linkItem = linkData.linksGroup[i]

                const { coords, page, content, label } = linkItem

                if (!coords) {
                    console.error('No coords for link', linkItem)
                    continue
                }

                const normalizedValue =
                    (linkData.type === 'money')
                        ? reportExtractedValuesQuery.data?.find((doc) => doc.id === linkItem.extractedValueId)?.normalizedValue ?? null
                        : null

                const annotation = await createAnnotation({
                    createAnnotations,
                    annotationsList,
                    padding: 1,
                    annotationManager,
                    pdfInstance: pdfInstance,
                    type: 'link',
                    pageIndex: page,
                    coordinates: coords,
                    showInNotesPanel: false,
                    toolName: CustomToolNames.CrossLink,
                    snapshotRerenderKey: reviewInProgress ? 'true' : 'false',
                    relatedSnapshotId: linkObject.id + '_' + i,
                    hidden: !reviewInProgress,
                    annotationVariant: normalizedValue && activeMoneyValues?.includes(normalizedValue.toString())
                        ? AnnotationVariant.moneyValueActive
                        : undefined,
                    reply: linkData.type === 'money' ? 'Same values found' : 'eCDF - Note',
                    excludeFromSummary: true,
                    customData: {
                        linkGroup: groupUniqueKey,
                        tickConfidence: 'link', // FIXME: Rename later to style/type. Related to Identified blocks type.
                        linkLabel: label,
                        ignoreStepKey: 'true',
                        toolName: CustomToolNames.CrossLink,
                        relatedLinkSnapshotId: linkObject.id,
                        linkIndex: linkData.linksGroup.indexOf(linkItem).toString(),
                        crossLInkType: linkData.type,
                        normalizedValue: normalizedValue,
                    },
                })
            }
        }
    }, [
        annotationManager,
        revewLinkObjectsLoading,
        pdfInstance, reportData?.currentStep,
        annotationsReady,
        reportExtractedValuesQuery.isLoading,
        reviewInProgress,
    ]);

    // Hide annotations on zoom for optimization
    // useEffect(() => {
    //     if(!pdfInstance) return
    //
    //     let isZooming = false;
    //     let zoomTimeout;
    //
    //     pdfInstance.Core.documentViewer.addEventListener('zoomUpdated', function(zoomLevel) {
    //         if (!isZooming) {
    //             isZooming = true;
    //             hideAnnotations();
    //         }
    //
    //         clearTimeout(zoomTimeout);
    //         zoomTimeout = setTimeout(() => {
    //             isZooming = false;
    //             showAnnotations();
    //         }, 200); // Adjust delay as needed
    //     });
    //
    //     function hideAnnotations() {
    //         pdfInstance.Core.annotationManager.hideAnnotations(
    //             pdfInstance.Core.annotationManager.getAnnotationsList().filter(ann => ann.getPageNumber() === page),
    //         );
    //     }
    //
    //     function showAnnotations() {
    //         pdfInstance.Core.annotationManager.showAnnotations(
    //             pdfInstance.Core.annotationManager.getAnnotationsList().filter(ann => ann.getPageNumber() === page),
    //         );
    //     }
    // }, [pdfInstance]);

    useEffect(() => {
        if (!documentViewer) return

        // Performance optimization
        documentViewer.disableAutomaticLinking();
    }, [documentViewer]);

    const createAnnotationsLIst = useRef<Core.Annotations.Annotation[]>([])

    // FIXME: To false
    const initialValuesRenderDone = useRef<boolean>(true)

    // Version for Downloading
    const [allAnnotationsSummaryPrepared, setAllAnnotationsSummaryPrepared] = useState(false)

    const [, setAnnotatonsReadyForSummary] = useQueryParam(QUERY_PARAMS_CONFIG.ANNOTATIONS_READY_FOR_SUMMARY.key, QUERY_PARAMS_CONFIG.ANNOTATIONS_READY_FOR_SUMMARY.type)

    // Summary annotations preparation in progress
    useEffect(() => {
        if (!reviewInProgress && !allAnnotationsSummaryPrepared) {
            setAnnotatonsReadyForSummary(false)
        } else {
            setAnnotatonsReadyForSummary(true)
        }
    }, [allAnnotationsSummaryPrepared, reviewInProgress]);

    const { client: veltClient } = useVeltClient()

    useEffect(() => {
        if (reviewInProgress) {
            // Reset if we get back to review
            setAnnotatonsReadyForSummary(false)
        }
    }, [reviewInProgress]);

    const createAnnotations = useCreateAnnotations()

    const [lazyLoadingInProgress, setLazyLoadingInProgress] = useState(false)
    
    useBoolTime('Annot page lazy loading', lazyLoadingInProgress, 200)

    const [appliedAnnotationIds, setAppliedAnnotationIds] = useState<Set<string>>(new Set())

    const isAnnotationExtractedValueWithAComment = (annotation: Core.Annotations.Annotation) => {
        const isExtractedValue = annotation.getCustomData(CustomDataKey.annotationVariant) === AnnotationVariant.moneyValue || annotation.getCustomData(CustomDataKey.annotationVariant) === AnnotationVariant.moneyValueActive
        const haveReplyForSnapId = ananotationReplyOnSnapQuery.data?.some(reply => reply.parentSnapId === annotation.getCustomData(CustomDataKey.relatedSnapshotId)) || false

        return isExtractedValue && haveReplyForSnapId
    }
    
    /**
     * Lazy apply of extracted 'Money' annotations
     */
    useAsyncEffect(async () => {
        if (
            !createAnnotations ||
            reportExtractedValuesQuery.isLoading ||
            !annotationManager ||
            !annotationsReady ||
            !pdfInstance ||
            !documentViewer ||
            valueValidationQuery.isLoading ||
            // Skip annotations rendering if scrolling
            isScrolling
        ) return

        // Apply all annotations only once
        if (allAnnotationsSummaryPrepared === true && !reviewInProgress) {
            return
        }

        setLazyLoadingInProgress(true)

        // Show hidden annotations
        pdfInstance.Core.annotationManager.showAnnotations(
            pdfInstance.Core.annotationManager.getAnnotationsList().filter(ann => ann.getPageNumber() === page),
        );

        const applyForPages =
            reviewInProgress
                ? createRange(page, CONFIG.LAZY_ANNOTATIONS_PAGE_DISTANCE, 1, documentViewer.getPageCount())
                // Apply for all pages to make sure all annotations are downloadable
                : createRange(1, documentViewer.getPageCount(), 1, documentViewer.getPageCount())

        const step = reviewInProgress ? 10 : 200

        const ecdfLInkGroups =
            reviewLinkObjectsSnap?.docs.map(doc => doc.data()).filter(el => el.type === 'ecdfNoteLink')
                .map(el => el.linksGroup).flat()

        const moneyValues = reportExtractedValuesQuery.data
            // Only pages in nearest range and values with comments
            ?.filter((el) => {
                return applyForPages.includes(el.page + 1) || (ananotationReplyOnSnapQuery.data?.some(reply => reply.parentSnapId === el.id ) || false)
            })
            .filter(el => (el.rejected !== true))
            // Exlude values which have link with notes sections. It's for sure invalid money values
            .filter(el => {
                // Skip if no coordinates
                if (!el.coordinates) return true;

                // Check intersection with each ecdfLink group item
                return !ecdfLInkGroups.some(linkItem => {
                    if (!linkItem.coords) return false;

                    const moneyValueRect = {
                        ...el.coordinates,
                        page: el.page,
                    }

                    const linkRect = {
                        x0: linkItem.coords[0],
                        y0: linkItem.coords[1],
                        width: linkItem.coords[2],
                        height: linkItem.coords[3],
                        page: linkItem.page,
                    };

                    const isIntersecting = calculateRectIntersection(moneyValueRect, linkRect, 10);

                    return isIntersecting;
                });
            })

        // Filter out already applied annotations
        const pendingMoneyValues = moneyValues.filter(value => !appliedAnnotationIds.has(value.id))
        const slicedMoneyValues = pendingMoneyValues.slice(0, step)

        const annotationsList = annotationManager.getAnnotationsList()
        const newlyAppliedIds = new Set<string>()

        for (const moneyValue of slicedMoneyValues) {
            const { coords, page: annotPage, originalValue, normalizedValue, coordinates } = moneyValue

            if (reviewInProgress) {
                const annotation = createExtractedValueAnnotation({
                    doNotCreate: true,
                    pdfInstance,
                    moneyValue,
                    annotationsList,
                    annotationManager,
                    activeMoneyValues,
                    createAnnotations,
                    authData,
                })

                if (!annotation) continue

                createAnnotationsLIst.current.push(annotation)
            } else {
                // Hide money values in result PDF
                hideBySnapId({
                    annotationsList,
                    annotationManager,
                    snapId: moneyValue.id,
                })
            }

            newlyAppliedIds.add(moneyValue.id)

            const relatedValueValidation = valueValidationQuery.data?.find((doc) => {
                return doc.extractedValueId === moneyValue.id
            })

            if (relatedValueValidation) {
                const annotation = updateAnnotationTicks(relatedValueValidation, moneyValue, annotationsList)
                if (annotation) {
                    createAnnotationsLIst.current.push(annotation)
                }
            }
        }

        const pagesToClean = prefPagesApplied.current.filter(page => !applyForPages.includes(page))

        if (pagesToClean.length) {
            // Only delete annotations for pages that aren't in the current applyForPages
            const annotToHide = annotationsList.filter(annot => {
                const isMoneyVal = annot.getCustomData(CustomDataKey.annotationVariant) === AnnotationVariant.moneyValue
                const isMonewValWithAnnot = isAnnotationExtractedValueWithAComment(annot)
                const isThisPageToClean = pagesToClean.includes(annot.PageNumber)
                
                return isThisPageToClean && isMoneyVal && !isMonewValWithAnnot
            })

            if (annotToHide.length && reviewInProgress) {
                annotationManager.hideAnnotations(annotToHide);
            }
        }

        prefPagesApplied.current = applyForPages

        if (createAnnotationsLIst.current.length) {
            const list = (await Promise.all(createAnnotationsLIst.current))
                .filter(Boolean)
            createAnnotations(list)
            createAnnotationsLIst.current = []
        }

        if (!initialValuesRenderDone.current && reviewInProgress) {
            // hide non active pages annotations
            annotationManager.hideAnnotations(
                annotationManager.getAnnotationsList().filter(ann => !applyForPages.includes(ann.getPageNumber()) && ann.getPageNumber() !== page),
            );
            initialValuesRenderDone.current = true
        }

        // Restart if we have more items left
        if(pendingMoneyValues.length > step) {
            setLazyLoadingInProgress(true)
            setTimeout(() => {
                // This update will re-trigger the useEffect again
                setAppliedAnnotationIds(prev => new Set([...prev, ...newlyAppliedIds]))
            }, 10)
        } else {
            setLazyLoadingInProgress(false)

            // Apply all annotations only once for summary
            if (!reviewInProgress) {
                setAllAnnotationsSummaryPrepared(true)
            }
        }
    }, [
        createAnnotations,
        annotationsReady,
        reportExtractedValuesQuery.isLoading,
        reportExtractedValuesQuery.data,
        annotationManager,
        pdfInstance,
        page,
        documentViewer,
        valueValidationQuery.isLoading,
        valueValidationQuery.data,
        isScrolling,
        tab,
        reviewInProgress,
        appliedAnnotationIds,
        ananotationReplyOnSnapQuery.dataUpdatedAt,
    ]);

    // Annot hover cursor change
    // useEffect(() => {
    //     if(!documentViewer || !pdfInstance) return
    //     documentViewer.addEventListener('toolModeUpdated', (tool) => {
    //         //
    //         if (tool instanceof pdfInstance.Core.Tools.PolygonCreateTool) {
    //             pdfInstance.Core.Tools.Tool.ENABLE_ANNOTATION_HOVER_CURSORS = false;
    //         } else {
    //             pdfInstance.Core.Tools.Tool.ENABLE_ANNOTATION_HOVER_CURSORS = true;
    //         }
    //     });
    // }, [documentViewer]);

    // FIt to width for small screen
    useEffect(() => {
        if (!pdfInstance) return

        // 15 inch Mac screen: 1512
        if (window.innerWidth < 1600) {
            pdfInstance.UI.setFitMode(pdfInstance.UI.FitMode.FitWidth)
        }
    }, [pdfInstance]);

    const [, setNavItems] = useLocalStorage<string[]>(ACTIVE_MENU_GROUP_LOCAL_STORAGE_KEY, []);

    const initVeltComments = useCallback((instance: WebViewerInstance, annotManager: Core.AnnotationManager) => {
        const itemsToAdd: Record<string, {
            type: string;
            title: string;
            img: string;
            onClick: () => void
            dataElement?: string
        }> = {
            'commentVelt': {
                type: 'actionButton',
                title: 'Comment',
                img: '/message.svg',
                onClick: () => {
                    setNavItems(['comments'])
                },
                dataElement: 'commentVelt',
            },
            // 'validation': {
            //     type: 'actionButton',
            //     title: 'Validation',
            //     img: '/check-square.svg',
            //     onClick: () => {
            //         setNavItems(['element'])
            //     },
            //     dataElement: 'validation',
            // },
        }
        const items = instance.UI.annotationPopup.getItems()

        Object.entries(itemsToAdd).forEach(([key, item]) => {
            // Exclude duplidated (they are possible when user goes back to list and open another report)
            if(items.find((el) => el.dataElement === item.dataElement)) return
            instance.UI.annotationPopup.add(item, key)
        })
    }, [veltClient])
    
    const veltIsOn = useFeatureOn('veltIsOn')

    useEffect(() => {
        if (!pdfInstance || !annotationManager || !veltClient || !veltIsOn) return

        initVeltComments(pdfInstance, annotationManager)
    }, [pdfInstance, annotationManager, veltClient]);

    const [tabLoading, setTabLoadingParam] = useQueryParam('tabLoading', BooleanParam);

    const inputFilesQuery = useInputFilesQuery({
        filters: [
            'reportId', '==', docId as string,
        ],
    }, {
        enabled: !!docId,
    });

    useAsyncEffect(async () => {
        await cleanUpOldFiles();
    }, [])

    /**
     * Preload all files for the review process
     */
    const filePromisesRef = useRef<Record<string, Promise<Blob | null>>>({});

    const [filePromisesReady, setFilePromisesReady] = useState(false);

    // First useEffect to initialize loading of all files
    useEffect(() => {
        if (!pdfInstance || inputFilesQuery.isLoading || reportSnapshotLoading) return;

        const loadDocument = async (fileId: string, bucketPath: string) => {
            let blob = null;
            try {
                const fieItem = await getFileFromIndexedDB(fileId)
                blob = fieItem?.blob
            } catch (e) {
                console.error('Error getting file from indexedDB', e);
            }

            if (!blob) {
                const url = await firebaseDownloadUrl(bucketPath);
                const response = await fetch(url);
                blob = await response.blob();
                try {
                    await saveFileToIndexedDB(fileId, blob);
                } catch (e) {
                    console.error('Error saving file to indexedDB', e);
                }
            }

            return blob;
        };

        if (inputFilesQuery.data?.length) {
            for (const file of inputFilesQuery.data) {
                const bucketPath = file.storagePath;
                const fileId = file.id;

                // Store the promise for loading the main file
                filePromisesRef.current[fileId] = loadDocument(fileId, bucketPath);
            }
        }

        setFilePromisesReady(true)
    }, [pdfInstance, inputFilesQuery.isLoading, reportSnapshot?.id]);

    // Add PDF loading tracing

    const { markStage, stopTrace } = usePerformanceTrace('viewerLoading3')

    useEffect(() => {
        markStage('PdfViewer: open')
    }, []);

    useEffect(() => {
        if (inputFilesQuery.isLoading) return
        markStage('PdfViewer: inputFileSnap ready')
    }, [inputFilesQuery.isLoading])

    // Second useEffect to load the file into the PDF Viewer
    useAsyncEffect(async () => {
        if (!pdfInstance || inputFilesQuery.isLoading || !tab || !filePromisesReady) return;

        markStage('PdfViewer: files loading started')

        const currentTab = inputFilesQuery.data?.find(el => el.id === tab) ? tab : inputFilesQuery.data?.find((file: InputFile) => file.fileType === 'currentYearFinancialStatement')?.id || inputFilesQuery?.data?.[0]?.id;

        if (currentTab !== tab && currentTab) {
            setTab(currentTab)
        }

        const filePromise = filePromisesRef.current[currentTab as string];

        if (filePromise) {
            try {
                setTabLoadingParam(true);
                const blob = await filePromise;
                if (blob) {
                    pdfInstance.UI.loadDocument(blob, {
                        extension: 'pdf',
                    });
                } else {
                    console.error('Error loading file from indexedDB');
                }
            } finally {
                setTabLoadingParam(false);
                markStage('PdfViewer: first tab loading complete')
                stopTrace()
            }
        } else {
            pdfInstance.UI.loadDocument(fileUrl, {
                extension: 'pdf',
            });
            markStage('PdfViewer: first tab url set')
            stopTrace()
        }
    }, [pdfInstance, inputFilesQuery.isLoading, tab, filePromisesReady]);

    const [, setIsAiChatOpen] = useQueryParam(AI_CHAT_QUERY_CONFIG.name, AI_CHAT_QUERY_CONFIG.type)

    // TODO: It can be initialized before Auth context ready
    useEffect(() => {
        if (instansInited.current) {
            return;
        }
        instansInited.current = true

        WebViewer(
            {
                path: VIEWER_LIB_STATIC_URL,
                licenseKey: 'NEXLY TECH CORP:OEM:Nexly::B+:AMS(20251204):465716021FC78AD0533353184F714F262292BC7DA3BF690187A7C96D4E21BEF5C7',
                // Allows to modify annotations from other users
                // FIXME: Allow to modify only yours
                isAdminUser: true,
                extension: ['pdf'],
                fullAPI: true,
            },
            viewer.current,
        ).then((instance: WebViewerInstance) => {
            const docViewer = instance.Core.documentViewer as Core.DocumentViewer;

            const { UI } = instance;
  
            // Enter side-by-side view
            // UI.enterMultiViewerMode();
            // UI.enableFeatures([UI.Feature.SideBySideView]);
  
            instance.Core.Annotations.setCustomDrawHandler(instance.Core.Annotations.RectangleAnnotation, function (ctx, pageMatrix, rotation, options) {
                // const similarAnnotations = pageMatrix.getPage().getAnnotations().filter(a => a instanceof instance.Core.Annotations.RectangleAnnotation);
                //
                // ctx.beginPath();
                // for (const annotation of similarAnnotations) {
                //     ctx.rect(annotation.X, annotation.Y, annotation.Width, annotation.Height);
                // }
                // ctx.fill();
                // ctx.stroke();

                options.originalDraw(ctx, pageMatrix, rotation);
            }, {
                generateAppearance: false,
                canvasMultiplier: 0,
            });

            // Keeps only main tools active on the panel
            instance.UI.disableElements([
                'toolbarGroup-Annotate',
                'toolbarGroup-Insert',
                'toolbarGroup-Shapes',
                'toolbarGroup-Measure',
                'toolbarGroup-Forms',
                'toolbarGroup-Edit',
                'toolbarGroup-FillAndSign',
                'toolbarGroup-View',
                'toolsHeader',
                'toolbarGroup-Edit',
                'tools-header',
                'toolbarGroup-Redact',
                'toolbarGroup-EditText',
                // Signature
                'CustomSave',
                'toolbarGroup-FillAndSign',
                'annotationClearSignatureButton',
                'outlinesPanelButton',
                'menuButton',
                // Editing actions in the pages navigation
                'thumbnailControl',
                // Right click popup
                'contextMenuPopup',
                'toolsOverlay', // Bar on the bottom when comments are acitve
            ]);

            // Turn off comments
            instance.UI.disableElements([
                veltIsOn ? 'annotationCommentButton' : undefined,
                !veltIsOn ? 'notesPanelButton' : undefined,
                veltIsOn ? 'notesPanel' : undefined,
                veltIsOn ? 'toggleNotesButton' : undefined,
                'redactButton',
                'freeTextToolButton',
                'multiSelectModeButton',
            ].filter(Boolean));

            // Note tooltip
            instance.UI.disableElements([
                'linkButton',
                'annotationStyleEditButton',
                'annotationGroupBu-tton',
            ]);

            // Comments
            instance.UI.disableElements([
                !veltIsOn ? 'noteState' : undefined,
                !veltIsOn ? 'addReplyAttachmentButton' : undefined,
            ].filter(Boolean));

            // Text popup
            instance.UI.disableElements([
                // 'textStrikeoutToolButton',
                // 'textSquigglyToolButton',
                // 'textHighlightToolButton',
                // 'textUnderlineToolButton',
            ])

            // instance.UI.textPopup.add({
            //     type: 'actionButton',
            //     title: 'Validation',
            //     img: '/check-square.svg',
            //     onClick: async () => {
            //         const selectionInfo = instance.Core.documentViewer.getSelectedTextQuads();
            //         const selectedText = instance.Core.documentViewer.getSelectedText();
            //         const pageNumbers = Object.keys(selectionInfo);
            //        
            //         // TODO: Make it possible to add pages array in extractedValue
            //         const pageNumber = pageNumbers[0]
            //
            //         const newItem: ReportExtractedValues = {
            //             companyId: authData.company.id,
            //             quads: selectionInfo,
            //             originalValue: selectedText || '',
            //             normalizedValue: selectedText || '',
            //             page: Number(pageNumber) - 1,
            //             pageNumber: pageNumber,
            //             reportId: docId as string,
            //             type: 'manual',
            //             createdAt: new Date(),
            //         };
            //        
            //         await reportExtractedValuesCreateMutation.mutateAsync({
            //             data: newItem,
            //         })
            //
            //     },
            //     dataElement: 'validation',
            // })

            instance.UI.textPopup.add(
                {
                    type: 'actionButton',
                    title: 'Ask Nexly AI',
                    img: '/ai.svg',
                    onClick: () => {
                        setIsAiChatOpen(true)
                    },
                },
            )

            addCustomTools(instance, docViewer)

            setPdfInstance(instance)
        })
            .catch((error) => {
                console.error('Error loading WebViewer:', error);

            })
    }, []);

    const { selectedExtractedValSnapId, setSelectedExtractedValSnapId, selectedAnnotationId } = useFocusedValueId()

    const appearedAnnotations = useRef<string[]>([])

    useRenderMeter()
    
    /**
     * Reset appeared annotations when page changes
     */
    useEffect(() => {
        appearedAnnotations.current = []
    }, [page]);

    /**
     * Comments section filter
     */
    useEffect(() => {
        if(!pdfInstance || !annotationManager) return
        
        const focusedAnnotations = annotationManager.getSelectedAnnotations();

        pdfInstance.UI.setCustomNoteFilter(annotation => {
            const focused = focusedAnnotations?.some(a => a.Id === annotation.Id) || false
            const haveReplyIdForAnnotation = ananotationReplyOnSnapQuery.data?.some(reply => reply.parentAnnotationId === annotation.Id) || false
            const annoatationFocusedById = selectedAnnotationId === annotation.Id
            const annotationFocusedBySnap = annotation.getCustomData(CustomDataKey.relatedSnapshotId) === selectedExtractedValSnapId
            const isExtractedValue = annotation.getCustomData(CustomDataKey.annotationVariant) === AnnotationVariant.moneyValue

            const showMoneyValue = isExtractedValue && annotationFocusedBySnap
            
            const isItExtractedValueWithAComment = isAnnotationExtractedValueWithAComment(annotation)

            if(annoatationFocusedById || annotationFocusedBySnap) {
                // Add new annotation ID and keep only the 5 most recent
                appearedAnnotations.current = [
                    annotation.Id,
                    ...appearedAnnotations.current.filter(id => id !== annotation.Id),
                    // Amount of items which can be kepn in history
                ].slice(0, 1)
            }

            const keptVisible = appearedAnnotations.current.includes(annotation.Id)

            return haveReplyIdForAnnotation || isItExtractedValueWithAComment || showMoneyValue || keptVisible || !isExtractedValue || focused
        });
    }, [pdfInstance, annotationManager, ananotationReplyOnSnapQuery.dataUpdatedAt, selectedExtractedValSnapId]);

    /**
     * Reset values when report open
     */
    useEffect(() => {
        setSelectedExtractedValSnapId(undefined)
    }, []);

    useEffect(() => {
        if(!pdfInstance || !Feature) return

        // Disable all hotkeys
        // pdfInstance.UI.hotkeys.off()

        pdfInstance.UI.disableFeatures([
            Feature.SavedSignaturesTab,
            Feature.WatermarkPanel,
            Feature.ContentEdit,
            Feature.FilePicker,
            Feature.Print,
            Feature.Redaction,
            // Feature.MouseWheelZoom, // Zoom with a mouth
            Feature.ContentEdit,
            Feature.Download,
            Feature.RightClickAnnotationPopup,
        ]);
    }, [pdfInstance, Feature]);

    useEffect(() => {
        if (!annotationManager) return

        // Identify user
        annotationManager.setCurrentUser(authData.user.displayName)

        /**
         * Disabled multiple annotations selection
         * Annotations listener properly handling now only one annotation
         */
        annotationManager.addEventListener('annotationSelected', (annotations) => {
            if (annotations.length > 1) {
                annotationManager.deselectAnnotations(annotations.splice(0))
            }

            // TODO: Later name it work only for customTools
            // annotations?.forEach((annotation) => {
            //     annotation.setRotationControlEnabled(false);
            // })
        })
    }, [annotationManager]);

    return (
        <>
            {lazyLoadingInProgress && (
                <Flex
                    style={{
                        position: 'absolute',
                        left: 16,
                        bottom: 32,
                        zIndex: 10,
                        padding: 8,
                        borderRadius: '50%',
                        background: 'white',
                    }}
                >
                    <Spin
                        size='small'
                    />
                </Flex>
            )}
            {tabLoading && (
                <Spin
                    size='large'
                    style={{ position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)' }}
                />
            )}
            <div
                style={{
                    'flex': 1,
                    'height': '100%',
                }}
                ref={viewer}
            />
        </>
    );
}

function openDatabase(): Promise<IDBDatabase> {
    return new Promise<IDBDatabase>((resolve, reject) => {
        const request = indexedDB.open('pdfViewerDB', 3);

        request.onupgradeneeded = (event) => {
            const db = request.result;

            // Delete old
            if (db.objectStoreNames.contains('files')) {
                db.deleteObjectStore('files');
            }

            // Create new
            const objectStore = db.createObjectStore('files', { keyPath: 'id' });

            // Create index
            if (!objectStore.indexNames.contains('timestamp')) {
                objectStore.createIndex('timestamp', 'timestamp', { unique: false });
            }
        };

        request.onsuccess = () => {
            resolve(request.result);
        };

        request.onerror = () => {
            reject(request.error);
        };
    });
}

async function saveFileToIndexedDB(id: string, blob: Blob): Promise<void> {
    const db = await openDatabase();
    const transaction = db.transaction('files', 'readwrite');
    const store = transaction.objectStore('files');
    const timestamp = Date.now();

    // Ensure the object includes the key specified by the keyPath
    const fileRecord: StoredFile = { 
        id,
        blob,
        timestamp,
    };

    store.put(fileRecord);

    return new Promise<void>((resolve, reject) => {
        transaction.oncomplete = () => resolve();
        transaction.onerror = () => reject(transaction.error);
    });
}

async function getFileFromIndexedDB(id: string): Promise<StoredFile | null> {
    const db = await openDatabase();
    const transaction = db.transaction('files', 'readonly');
    const store = transaction.objectStore('files');
    const request = store.get(id);

    return new Promise<StoredFile | null>((resolve, reject) => {
        request.onsuccess = () => resolve(request.result as StoredFile | null);
        request.onerror = () => reject(request.error);
    });
}

async function cleanUpOldFiles(): Promise<void> {
    const db = await openDatabase();
    const transaction = db.transaction('files', 'readwrite');
    const store = transaction.objectStore('files');

    // Check if the 'timestamp' index exists before using it
    if (!store.indexNames.contains('timestamp')) {
        console.error('Index \'timestamp\' not found in object store.');
        return;
    }

    const index = store.index('timestamp');
    const oneWeekAgo = Date.now() - 7 * 24 * 60 * 60 * 1000;

    return new Promise<void>((resolve, reject) => {
        const filesToDelete: string[] = [];
        const allFiles: StoredFile[] = [];

        transaction.oncomplete = () => resolve();
        transaction.onerror = () => reject(transaction.error);

        const request = index.openCursor();

        request.onsuccess = (event: Event) => {
            const cursor = (event.target as IDBRequest).result as IDBCursorWithValue | null;

            if (cursor) {
                const { id, timestamp } = cursor.value as StoredFile;
                allFiles.push({ id,
                    timestamp,
                    blob: cursor.value.blob });

                if (timestamp < oneWeekAgo) {
                    filesToDelete.push(id);
                }
                cursor.continue();
            } else {
                // Sort files by timestamp to find the oldest
                allFiles.sort((a, b) => a.timestamp - b.timestamp);

                // If more than 50 files, mark the oldest for deletion
                while (allFiles.length > 50) {
                    const oldest = allFiles.shift();
                    if (oldest) {
                        filesToDelete.push(oldest.id);
                    }
                }

                // Delete marked files in parallel
                const deletePromises = filesToDelete.map(id => {
                    return new Promise<void>((resolve, reject) => {
                        const deleteRequest = store.delete(id);
                        deleteRequest.onsuccess = () => resolve();
                        deleteRequest.onerror = () => reject(deleteRequest.error);
                    });
                });

                Promise.all(deletePromises)
                    .then(() => filesToDelete.length && (console.info(`Successfully deleted ${filesToDelete.length} old files`)))
                    .catch(error => console.error('Error deleting files:', error));
            }
        };

        request.onerror = () => {
            reject(request.error);
        };
    });
}
